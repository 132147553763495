import React from 'react';
import { Route, BrowserRouter as Router } from 'react-router-dom';
import { isTablet, isMobile, isIPad13 } from 'react-device-detect';

import { BadDeviceScreen } from '../screens';

export const UserAgentDetection = ({ children }) => {
  const userMedia = (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) || navigator.getUserMedia || navigator.webkitGetUserMedia || true;

  const isDeviceNotSupported = () => {
    if ((!isMobile && !isTablet && !isIPad13) || !userMedia) {
      return true;
    }
    return false;
  };

  if (isDeviceNotSupported()) {
    return (
      <Router>
        <Route path="/" component={BadDeviceScreen} />
      </Router>
    );
  }
  return children;
};
