import React from 'react';
import PropTypes from 'prop-types';

import { SubModelPropTypes } from '../../../SubModelsScreen/comoponents/SubModelSelect/SubModelSelect';
import { Tile, TilesContainer } from '../../../../components/Tile/Tile';

export const ModelSelect = ({ models, onModelSelect }) => {
  return (
    <TilesContainer>
      {models && models.map((model) => (
        <Tile key={model.id} thumbnail={model.thumbnail} altText={model.name} text={model.name} onTileClick={() => onModelSelect(model)}/>
      ))}
    </TilesContainer>
  );
};

export const ModelPropTypes = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  thumbnail: PropTypes.string.isRequired,
  submodels: PropTypes.arrayOf(SubModelPropTypes)
});

ModelSelect.propTypes = {
  models: PropTypes.arrayOf(ModelPropTypes).isRequired,
  onModelSelect: PropTypes.func.isRequired
};
