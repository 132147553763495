import React, { useRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

import { ContainerLayout } from '../../helpers/styles/layout';
import { lightGrey, headerHeight } from '../../constants/styleConstants';
import { withDeviceSize } from '../../helpers/withDeviceSize';

const photoBoxPadding = 5;
const containerPadding = 15;
const infoContainerHeight = 60;
const shopButtonHeight = 3.5;

const ComparisonContainer = styled.div`
  width: 100%;
  display: flex;
  background: ${lightGrey};
  height: calc(100% - 60px);
  padding: ${containerPadding}px;
  position: relative;
`;

const PhotosContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

const StyledPhotoBox = styled.div`
  position: relative;
  width: calc(50% - 5px);
  height: calc(85vh - ${infoContainerHeight}px - ${headerHeight}px - ${photoBoxPadding}px);
  margin: 0 ${photoBoxPadding}px ${photoBoxPadding}px 0;
  overflow: hidden;
`;

const InfoContainer = styled.div`
  position: absolute;
  bottom: 0;
  background: white;
  width: calc(100% - 35px);
  height: ${infoContainerHeight}px;
  color: black;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0 18px;
`;

const InfoText = styled.div`
  background: white;
  width: 100%;
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 18px;
`;

const InfoTitleText = styled.p`
  font-size: 12px;
  font-weight: bold;
  margin: 0 0 4px;
`;
const InfoSubTitleText = styled.p`
  font-size: 12px;
  margin: 0;
`;

const ContainerLayoutWrapper = styled(ContainerLayout)`
  min-height: 100vh;
  background: ${lightGrey};
`;

const PhotoTitle = styled.span`
  z-index: 1;
  position: absolute;
  top: 15px;
  left: 15px;
  font-size: 16px;
  letter-spacing: -0.2px;
  line-height: 20px;
`;

const ShopButton = styled.a`
  height: ${shopButtonHeight}em;
  background: black;
  color: white;
  display: flex;
  text-decoration: none;
  font-size: 0.75em;
  width: 26em;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 0.8em;
`;

export function ComparisonScreen(props) {
  const scale = 0.55;
  const { userCarPhoto, wheelsPosition, selectedRims, match, history, userCarPhotoWithNewWheels, width } = props;
  const { seriesId, subModelId } = match.params;
  const [photoStyles, setPhotoStyles] = useState(null);
  const [pictureOffsetTop, setPictureOffsetTop] = useState(null);
  const [containerHeight, setContainerHeight] = useState(null);
  const oldPhotoBoxRef = useRef(null);
  const photoRef = useRef(null);

  useEffect(() => {
    if (!userCarPhoto.base64 || !wheelsPosition || !selectedRims) {
      history.push(`/models/${seriesId}/submodels/${subModelId}/camera`);
    }
  }, [userCarPhoto, wheelsPosition, selectedRims]);

  useEffect(() => {
    setPhotoStyles({
      marginLeft: `-${pictureOffsetTop}px`,
      marginTop: `-${wheelsPosition && wheelsPosition.fullImage.frontPositionY * scale - oldPhotoBoxRef.current.clientHeight / 2}px`,
      transform: `scale(${scale})`,
      transformOrigin: '0 0'
    });

    setContainerHeight({
      maxHeight: `${wheelsPosition &&
        (photoRef.current.clientHeight - wheelsPosition.fullImage.frontPositionY - wheelsPosition.fullImage.radius) * scale * 2}px`
    });
  }, [oldPhotoBoxRef, pictureOffsetTop, width]);

  useEffect(() => {
    setPictureOffsetTop(wheelsPosition && wheelsPosition.fullImage.frontPositionX * scale - oldPhotoBoxRef.current.clientWidth / 2);
  }, [containerHeight]);

  return (
    <ContainerLayoutWrapper>
      <ComparisonContainer>
        <PhotosContainer>
          <StyledPhotoBox ref={oldPhotoBoxRef} style={containerHeight}>
            <PhotoTitle>
              <FormattedMessage id="comparison-screen.original" />
            </PhotoTitle>
            <img style={photoStyles} ref={photoRef} src={userCarPhoto && userCarPhoto.base64} alt="your car" />
          </StyledPhotoBox>
          <StyledPhotoBox style={containerHeight}>
            <PhotoTitle>
              <FormattedMessage id="comparison-screen.new" />
            </PhotoTitle>
            <img style={photoStyles} src={userCarPhotoWithNewWheels && userCarPhotoWithNewWheels.base64} alt="your car with new rim" />
          </StyledPhotoBox>
          <InfoContainer>
            <InfoText>
              <InfoTitleText>{selectedRims && selectedRims.info.title}</InfoTitleText>
              <InfoSubTitleText>{selectedRims && selectedRims.info.subtitle}</InfoSubTitleText>
            </InfoText>
            <ShopButton
              onClick={() => {
                window.onbeforeunload = undefined;
              }}
              href={selectedRims ? selectedRims.info.shoplink : 'https://shops.audi.com/de_DE/web/zubehoer/'}
            >
              <FormattedMessage id="comparison-screen.shop" />
            </ShopButton>
          </InfoContainer>
        </PhotosContainer>
      </ComparisonContainer>
    </ContainerLayoutWrapper>
  );
}

export const EnchancedComparisonScreen = withDeviceSize(ComparisonScreen);
