import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { SubModelsScreen } from './SubModelsScreen';
import { setSelectedModel, removeSelectedModel } from '../../actions/selectedModel';
import { setSelectedSubModel, removeSelectedSubModel } from '../../actions/selectedSubModel';
import { setSelectedSeries, removeSelectedSeries } from '../../actions/selectedSeries';

function ConnectedSubModelsScreen(props) {
  return (
    <SubModelsScreen {...props} />
  );
}

function mapStateToProps({ applicationData, selectedSubModel, selectedSeries, selectedModel, applicationStep }) {
  return {
    applicationData,
    selectedSubModel,
    selectedSeries,
    selectedModel,
    applicationStep
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    setSelectedModel,
    removeSelectedModel,
    setSelectedSubModel,
    removeSelectedSeries,
    setSelectedSeries,
    removeSelectedSubModel
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ConnectedSubModelsScreen);