import React, { useEffect } from 'react';
import styled from 'styled-components';
import { compose, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { isTablet, isSafari } from 'react-device-detect';
import innerHeight from 'ios-inner-height';

import { darkGrey, lightGrey } from '../constants';
import closeIcon from '../assets/ico/cancel-white.svg';
import { ButtonComponent } from '.';
import { changeApplicationBlockHistoryPush } from '../actions/applicationState';

const WarningMessageWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
`;

const WarningMessage = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 184px;
  width: 362px;
  position: relative;
  padding: 20px 48px 20px 20px;
  background-color: ${darkGrey};

  span {
    color: ${lightGrey};
    font-size: 18px;
    letter-spacing: -0.2px;
    line-height: 20px;
  }
`;

const CloseMessage = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  width: 48px;
  height: 48px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: flex-end;

  button {
    &:not(:first-child) {
      margin-left: 10px;
    }
  }
`;

const WarningMessageComponent = props => {
  const { setModalType, nextLocation, history, changeApplicationBlockHistoryPush } = props;

  useEffect(() => {
    changeApplicationBlockHistoryPush(true);
    document.getElementById('root').style.overflow = 'hidden';
    document.getElementById('root').style.height = isTablet && isSafari ? `${window.innerHeight}px` : `${innerHeight()}px`;

    return () => {
      changeApplicationBlockHistoryPush(false);
      document.getElementById('root').style.overflow = null;
      document.getElementById('root').style.height = null;
    };
  }, []);

  return (
    <WarningMessageWrapper>
      <WarningMessage>
        <CloseMessage onClick={() => setModalType('')} src={closeIcon} alt="close" />
        <FormattedMessage id="error-message-modal.message" />
        <ButtonsWrapper>
          <ButtonComponent
            onClick={() => {
              setModalType('');
            }}
          >
            <FormattedMessage id="button.no" />
          </ButtonComponent>
          <ButtonComponent
            onClick={() => {
              setModalType('');
              history.push(nextLocation);
            }}
            primary
          >
            <FormattedMessage id="button.yes" />
          </ButtonComponent>
        </ButtonsWrapper>
      </WarningMessage>
    </WarningMessageWrapper>
  );
};

function mapStateToProps({ applicationState }) {
  return {
    nextLocation: applicationState.nextLocation
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      changeApplicationBlockHistoryPush
    },
    dispatch
  );
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(WarningMessageComponent);
