export const setRimsSeason = (season, intl) => {
  switch (season) {
    case 'Sommer':
      return intl.formatMessage({ id: 'season.summer' });
    case 'Winter':
      return intl.formatMessage({ id: 'season.winter' });
    default:
      return intl.formatMessage({ id: 'season.summer' });
  }
};
