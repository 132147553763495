const setUserCarPhotoWithNewWheelsActionType = 'SET_USER_CAR_PHOTO_WITH_NEW_WHEELS';
const setUserCarPhotoWithNewWheelsAction = (data) => ({
  type: setUserCarPhotoWithNewWheelsActionType,
  payload: data
});


const setUserCarPhotoWithNewWheels = (data) => dispatch => {
  dispatch(setUserCarPhotoWithNewWheelsAction(data));
};

export {
  setUserCarPhotoWithNewWheelsActionType,
  setUserCarPhotoWithNewWheels
}