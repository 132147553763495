import { createGlobalStyle } from 'styled-components';

import AudiTypeScreenLight from './assets/fonts/AudiTypeScreen-Light.ttf';
import AudiTypeScreenNormal from './assets/fonts/AudiTypeScreen-Normal.ttf';
import AudiTypeScreenBold from './assets/fonts/AudiTypeScreen-Bold.ttf';

export const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    cursor:pointer;
    -webkit-tap-highlight-color:rgba(0,0,0,0)
  }
  
  html {
    -webkit-text-size-adjust: none;
	  touch-action: manipulation;
	}
  
  body {
    background: black;
    color: white;
    font-family: AudiTypeScreen;
    margin: 0;
    padding: 0;
  }
  
  @font-face {
    font-family: AudiTypeScreen;
    src: url('${AudiTypeScreenLight}') format('truetype');
    font-weight: 300
  }
  
  @font-face {
    font-family: AudiTypeScreen;
    src: url('${AudiTypeScreenNormal}') format('truetype');
    font-weight: normal
  }
  
   @font-face {
    font-family: AudiTypeScreen;
    src: url('${AudiTypeScreenBold}') format('truetype');
    font-weight: bold
  }
  
  textarea,
  button,
  input.text,
  input[type="text"],
  input[type="button"],
  input[type="submit"] {
    -webkit-appearance: none;
    border-radius: 0;
    border: 0;
    background: transparent;
    outline: none;
  }
`;
