import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { zIndexes } from '../../constants';

const StyledButton = styled.button`
  background: transparent;
  border: 0;
  position: absolute;
  transform: translateY(-50%);
  z-index: ${zIndexes.cameraButtons};
  touch-action: manipulation;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  outline: none;
`;

export const CameraButton = ({ onClick, className, children }) => (
  <StyledButton type="button" onClick={onClick} className={className}>
    {children}
  </StyledButton>
);

CameraButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string.isRequired,
};