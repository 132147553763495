import { setSelectedSeriesActionType, removeSelectedSeriesActionType } from '../actions/selectedSeries';

const initialState = null;

const selectedSeries = (state = initialState, action) => {
  switch (action.type) {
    case `${setSelectedSeriesActionType}`:
      return action.payload;
    case `${removeSelectedSeriesActionType}`:
      return initialState;
    default:
      return state;
  }
};

export default selectedSeries