import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap.css';
import { injectIntl, intlShape } from 'react-intl';

import { ListComponent } from './components';

import infoIcon from '../../assets/ico/info-icon.png';
import summerIcon from '../../assets/ico/summer-tires.png';
import winterIcon from '../../assets/ico/winter-tires.png';
import rimSize16 from '../../assets/ico/tire-option-16.png';
import rimSize17 from '../../assets/ico/tire-option-17.png';
import rimSize18 from '../../assets/ico/tire-option-18.png';
import rimSize19 from '../../assets/ico/tire-option-19.png';
import rimSize15 from '../../assets/ico/tire-option-15.png';
import rimSize20 from '../../assets/ico/tire-option-20.png';
import rimSize21 from '../../assets/ico/tire-option-21.png';
import rimSize22 from '../../assets/ico/tire-option-22.png';
import rimSize23 from '../../assets/ico/tire-option-23.png';
import unknownSize from '../../assets/ico/unknown-ico.svg';

import './styles.css';

const MenuContainer = styled.div`
  width: 250px;
  display: flex;
  padding: 0 5px;
  justify-content: space-between;
`;

const RimName = styled.div`
  width: 100px;
  display: flex;
  align-items: center;
`;

const RimsMenuItemsWrapper = styled.div`
  width: 125px;
  img {
    height: 33px;
    width: 33px;

    &:not(:first-child) {
      margin-left: 10px;
    }
  }
`;

const TooltipOverlay = styled.div`
  p {
    margin: 0 0 12px 0;
  }
`;

const RimsMenuComponent = ({
  intl,
  selectedRims,
  availablesSeasonsAndSizes,
  currentSeason,
  currentSize,
  setSelectedRimSize,
  setSelectedRimSeason,
  applyFilter,
  appliedFilter,
}) => {
  const [isTooltipVisible, toggleVisibility] = useState(false);
  const [clickedTooltip, switchTooltips] = useState(null);

  const getSeasons = () => {
    const seasons = [];

    availablesSeasonsAndSizes.map(availablesRimAndSeason => {
      return seasons.push(availablesRimAndSeason.season);
    });

    return seasons;
  };

  const getRimsSizes = () => {
    const rimsSize = [];

    availablesSeasonsAndSizes.forEach(availableRimAndSeason => {
      if (availableRimAndSeason.season === currentSeason) {
        availableRimAndSeason.availableRimsSizes.forEach(availableRimSize => {
          rimsSize.push(availableRimSize);
        });
      }
    });
    return rimsSize.sort().reverse();
  };

  const rimIcons = {
    23: rimSize23,
    22: rimSize22,
    21: rimSize21,
    20: rimSize20,
    19: rimSize19,
    18: rimSize18,
    17: rimSize17,
    16: rimSize16,
    15: rimSize15
  };

  const setRimsIcon = rimSize => rimIcons[+rimSize] || unknownSize;

  const setSeasonIcon = season => {
    switch (season) {
      case intl.formatMessage({ id: 'season.summer' }):
        return summerIcon;
      case intl.formatMessage({ id: 'season.winter' }):
        return winterIcon;
      default:
        return null;
    }
  };

  return (
    <MenuContainer>
      <RimName>{selectedRims.info.title}</RimName>
      <RimsMenuItemsWrapper>
        <Tooltip
          id="1"
          placement="topLeft"
          trigger="click"
          visible={isTooltipVisible && clickedTooltip === '1'}
          onVisibleChange={toggleVisibility}
          onClick={() => switchTooltips('1')}
          overlay={
            <div>
              {selectedRims && selectedRims.info && (
                <TooltipOverlay>
                  <p>{selectedRims.info.subtitle}</p>
                </TooltipOverlay>
              )}
            </div>
          }
        >
          <img src={infoIcon} alt="info" />
        </Tooltip>
        <Tooltip
          id="2"
          placement="top"
          trigger="click"
          visible={isTooltipVisible && clickedTooltip === '2'}
          onVisibleChange={toggleVisibility}
          onClick={() => switchTooltips('2')}
          overlay={<ListComponent toggleVisibility={toggleVisibility} items={getSeasons()} selectedValue={appliedFilter !== 'none' && currentSeason} onItemClick={(...rest) => {applyFilter('weather'); setSelectedRimSeason(...rest)}} />}
        >
          <img src={setSeasonIcon(currentSeason)} alt="season" />
        </Tooltip>
        <Tooltip
          id="3"
          placement="top"
          trigger="click"
          visible={isTooltipVisible && clickedTooltip === '3'}
          onVisibleChange={toggleVisibility}
          onClick={() => switchTooltips('3')}
          overlay={
            <ListComponent rimsList items={getRimsSizes()} selectedValue={appliedFilter === 'rimSize' && currentSize} onItemClick={(...rest) => {applyFilter('rimSize'); setSelectedRimSize(...rest)}} toggleVisibility={toggleVisibility} />
          }
        >
          <img src={setRimsIcon(currentSize)} alt="rims size" />
        </Tooltip>
      </RimsMenuItemsWrapper>
    </MenuContainer>
  );
};

RimsMenuComponent.propTypes = {
  intl: intlShape.isRequired,
  currentSeason: PropTypes.string.isRequired,
  currentSize: PropTypes.number.isRequired,
  selectedRims: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    thumbnail: PropTypes.string,
    info: PropTypes.object,
    price: PropTypes.string,
    type: PropTypes.string,
    size: PropTypes.string
  }).isRequired
};

export default injectIntl(RimsMenuComponent);
