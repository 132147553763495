import React, { useCallback, useRef } from "react";
import styled from "styled-components";
import { darkGrey } from "../../constants";

/**
 * @fileoverview this component's objective is to show a right arrow if the
 * respective box can be scrolled to the left, otherwise it will show a right
 * arrow if can be scrolled right. This is a nice UX touch for touchscreens,
 * because it makes very clear that user can scroll
 * @author Overclocked Skid <antonio.martos.harres@mail.zenbit.tech>
 */

/**
 * @typedef {React.PropsWithChildren<{
 *   display: React.CSSProperties["display"],
 *   pxToScroll: number,
 *   additionalStyles: StyledComponent<"div", any, {}, never>,
 *   width?: React.CSSProperties["width"]
 * >} HorizontalScrollBoxProps
 * @type {React.FunctionComponent<HorizontalScrollBoxProps>}
 */

const HorizontalScrollBox = ({children, display, pxToScroll, additionalStyles, width = 'inherit'}) => {
    /** Ref used to scroll when we press the scroll button */
    const scrollRef = useRef(null);

    const ScrollBox = styled.div`
        overflow-x: scroll;
        width: ${width};
        display: ${display};
        scroll-behavior: smooth;
        ${additionalStyles}

        /* elegant round scroll */
        &:-webkit-scrollbar-thumb {
            border: 4px solid transparent;
            border-radius: 8px;
            background-clip: padding-box;
        }
    `

    /** @type {React.CSSProperties['width']} */
    const buttonWidth = '25px;'

    const LeftButton = styled.button`
        position: fixed;
        /* Workaround to make the position in the center of parent element */
        bottom: 0;
        transform: translateY(0);
        height: 52px;
        background-color: ${darkGrey};
        font-weight: bolder;
        z-index: 1337;
        width: ${buttonWidth};
        opacity: 0.85;
    `

    const RightButton = styled.button`
        position: fixed;
        /* Workaround to make the position in the center of parent element */
        bottom: 0;
        transform: translateY(0);
        height: 52px;
        background-color: ${darkGrey};
        font-weight: bolder;
        z-index: 1337;
        width: ${buttonWidth};
        opacity: 0.85;
        right: 0;
    `

    const PaddingLeft = styled.div`
        padding-left: ${buttonWidth};
    `

    const PaddingRight = styled.div`
        padding-right: ${buttonWidth};
    `

    /** Handled by prev-btn */
    const scrollToLeft = useCallback(() => {
        const { current } = scrollRef;
        const { scrollLeft } = current;

        current.scrollTo({
            left: scrollLeft - pxToScroll,
            top: 0,
            behaviour: "smooth",
        })
    }, [pxToScroll]);

    /** Handled by next-btn */
    const scrollToRight = useCallback(() => {
        const { current } = scrollRef;
        const { scrollLeft } = current;

        current.scrollTo({
            left: scrollLeft + pxToScroll,
            top: 0,
            behaviour: "smooth",
        })
    }, [pxToScroll]);

    return <ScrollBox ref={scrollRef}>
        <LeftButton className="prev-btn" type="button" onClick={scrollToLeft}>{'<'}</LeftButton>
            <PaddingLeft />
                {children}
            <PaddingRight />
        <RightButton className="next-btn" type="button" onClick={scrollToRight}>{'>'}</RightButton>
    </ScrollBox>
}

export {HorizontalScrollBox}
