import React, { useEffect, useState } from 'react';
import innerHeight from 'ios-inner-height';
import { isTablet, isSafari } from 'react-device-detect';

export function withDeviceSize(WrappedComponent) {
  return props => {
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(isTablet && isSafari ? window.innerHeight : innerHeight());

    const updateWindowDimensions = () => {
      setWidth(window.innerWidth);
      setHeight(isTablet && isSafari ? window.innerHeight : innerHeight());
    };

    useEffect(() => {
      window.addEventListener('resize', updateWindowDimensions);
      return () => {
        window.removeEventListener('resize', updateWindowDimensions);
      };
    });

    return (
      <WrappedComponent width={width} height={height} {...props} />
    )
  }
}