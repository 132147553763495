import React from 'react';
import styled from 'styled-components';

import { ReactComponent as InfoIcon } from '../../assets/images/info_icon.svg';
import { BottomBar } from './BottomBar';

const StyledInfoIcon = styled(InfoIcon)`
  margin-right: 10px;
`;

const BottomBarInfoContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0 20px;
  height: 100%;
`;

export function InfoBottomBar({ children }) {
  return (
    <BottomBar>
      <BottomBarInfoContainer>
        <StyledInfoIcon />{children}
      </BottomBarInfoContainer>
    </BottomBar>
  )
}