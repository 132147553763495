import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { grey, zIndexes } from '../constants';

const Wrapper = styled.div`
  position: fixed;
  z-index: ${zIndexes.alert};
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background: ${grey};
`;

const AlertWrapperComponent = ({ children, history }) => {
  useEffect(() => {
    const unblock = history.block(() => false);

    return () => unblock();
  }, []);
  return <Wrapper>{children}</Wrapper>;
};

export default withRouter(AlertWrapperComponent);
