const applicationStateBlockHistoryPushActionType = 'APPLICATION_BLOCK_HISTORY_PUSH';
const setApplicationStateNextLocationActionType = 'APPLICATION_NEXT_LOCATION';

const applicationBlockHistoryPushAction = state => ({
  type: applicationStateBlockHistoryPushActionType,
  payload: state
});

const setApplicationStateNextLocationAction = nextLocation => ({
  type: setApplicationStateNextLocationActionType,
  payload: nextLocation
});

const changeApplicationBlockHistoryPush = state => dispatch => {
  dispatch(applicationBlockHistoryPushAction(state));
};

const setApplicationStateNextLocation = nextLocation => dispatch => {
  dispatch(setApplicationStateNextLocationAction(nextLocation));
};

export {
  changeApplicationBlockHistoryPush,
  applicationStateBlockHistoryPushActionType,
  setApplicationStateNextLocation,
  setApplicationStateNextLocationActionType
}