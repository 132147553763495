import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { lightGrey, darkGrey } from '../../constants';

const TileContainer = styled.a`
  width: 100%;
  background: white;
  position: relative;
  padding-top: 71%;
  display: block;
`;

const TileInnerContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const StyledImg = styled.img`
  width: 100%;
  position: absolute;
  bottom: 10%;
`;

const StyledText = styled.span`
  position: absolute;
  color: black;
  font-size: ${props => (props.textSize ? props.textSize : 16)}px;
  font-weight: bold;
  left: 10%;
  top: 8%;
  font-family: AudiTypeScreen;

  @media only screen and (max-width: 480px) {
    font-size: ${props => (props.textSize ? props.textSize * 0.85 : 14)}px;
  }
`;

const StyledSubText = styled.span`
  position: absolute;
  color: black;
  font-size: 12px;
  left: 10%;
  top: 25%;
  font-family: AudiTypeScreen;
  color: ${darkGrey};
`;

const numberOfColumns = 3;
const tileRatio = 0.7;
const columnSizePercent = 100 / numberOfColumns;

export const TilesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
  min-height: calc(100vh - 40px);
  background: ${lightGrey};
  > a {
    width: calc(${columnSizePercent}% - 10px);
    margin: 5px;
    padding-top: calc((${columnSizePercent}% - 10px) * ${tileRatio});
    overflow: hidden;
    height: 0;
  }
`;

export const TypePanel = styled.div`
  width: 100%;
  display: flex;
  height: fit-content;
  flex-wrap: wrap;
  position: relative;
  > a {
    width: calc(${columnSizePercent}% - 10px);
    margin: 5px;
    padding-top: calc((${columnSizePercent}% - 10px) * ${tileRatio});
    overflow: hidden;
    height: 0;
  }
`;
export const TypePanelHeader = styled.div`
  color: black;
  width: 100%;
  height: 50px;
  padding: 0px 5px;
  margin-bottom: 5px;
  font-size: 24px;
  &:after {
    content: '';
    border-bottom: 2px solid;
    width: 75%;
    position: absolute;
    top: 40px;
    left: 5px;
  }
`;

export const Tile = ({ thumbnail, altText, text, subheader, onTileClick, textSize }) => {
  return (
    <TileContainer onClick={onTileClick}>
      <TileInnerContainer>
        {text && <StyledText textSize={textSize}>{text}</StyledText>}
        {subheader && <StyledSubText>{subheader}</StyledSubText>}
        <StyledImg src={thumbnail} alt={altText} />
      </TileInnerContainer>
    </TileContainer>
  );
};

Tile.propTypes = {
  thumbnail: PropTypes.string.isRequired,
  altText: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  onTileClick: PropTypes.func.isRequired,
  textSize: PropTypes.number
};

Tile.defaultProps = {
  textSize: 16
};
