import React, { useState, useEffect } from 'react';
import { Route, BrowserRouter as Router } from 'react-router-dom';

import { SplashScreen, BadOrientationScreen, BadDeviceMessageComponent } from './screens';
import { UserAgentDetection, DeviceOrientationDetection } from './services';
import { HeaderComponent } from './components';
import { GlobalStyle } from './globalStyles';

import ConnectedModelsScreen from './screens/ModelsScreen/ConnectedModelsScreen';
import ConnectedCameraScreen from './screens/CameraScreen/ConnectedCameraScreen';
import ConnectedWheelsPositioningScreen from './screens/WheelsPositioningScreen/ConnectedWheelsPositioningScreen';
import ConnectedSubModelsScreen from './screens/SubModelsScreen/ConnectedSubModelsScreen';
import ConnectedPickWheelsScreen from './screens/PickWheelsScreen/ConnectedPickWheelsScreen';
import ConnectedComparisonScreen from './screens/ComparisonScreen/ConnectedComparisonScreen';

import './App.css';
import { ModalComponent, WarningMessageComponent } from './shared-ui';

function App(props) {
  const { getApplicationData } = props;
  const [orientation, changeOrientation] = useState(DeviceOrientationDetection());

  const disableZoom = event => {
    if (event.scale && event.scale !== 1) {
      event.preventDefault();
    }
  };

  useEffect(() => {
    getApplicationData();
    window.addEventListener('gesturestart', disableZoom.bind(this), { passive: false });
    window.addEventListener('touchmove', disableZoom.bind(this), { passive: false });
    window.addEventListener('touchend', disableZoom.bind(this), { passive: false });
  }, []);

  window.onorientationchange = () => {
    changeOrientation(DeviceOrientationDetection());
  };

  const [modalType, setModalType] = useState('');

  const modalSwitch = type => {
    switch (type) {
      case 'BadDevice':
        return (
          <ModalComponent setModalType={setModalType}>
            <BadDeviceMessageComponent />
          </ModalComponent>
        );
      case 'Warning':
        return <WarningMessageComponent setModalType={setModalType} />;
      default:
        return null;
    }
  };

  return (
    <React.Fragment>
      <GlobalStyle />
      <UserAgentDetection setModalType={setModalType}>
        <Router>
          {orientation === 'portrait' && <BadOrientationScreen />}
          {modalType !== '' && modalSwitch(modalType)}
          <Route path="/:models?/:seriesId?/:subModel?/:subModelId?/:camera?" component={() => <HeaderComponent setModalType={setModalType} />} />
          <Route exact path="/" component={SplashScreen} />
          <Route exact path="/models/:seriesId?" component={ConnectedModelsScreen} />
          <Route exact path="/models/:seriesId/submodels/:subModelId?" component={ConnectedSubModelsScreen} />
          <Route exact path="/models/:seriesId/submodels/:subModelId/camera" component={ConnectedCameraScreen} />
          <Route exact path="/models/:seriesId/submodels/:subModelId/wheels-positioning" component={ConnectedWheelsPositioningScreen} />
          <Route exact path="/models/:seriesId/submodels/:subModelId/pick-wheels" component={ConnectedPickWheelsScreen} />
          <Route exact path="/models/:seriesId/submodels/:subModelId/comparison" component={ConnectedComparisonScreen} />
        </Router>
      </UserAgentDetection>
    </React.Fragment>
  );
}

export default App;
