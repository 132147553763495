import styled from 'styled-components';

import { darkGrey } from '../../../constants';

export const H1 = styled.h1`
    color: ${darkGrey};
    font-size: 16px;
    letter-spacing: -0.2px;
    line-height: 20px;
`;

export const Link = styled.a`
    color: ${darkGrey};

    &:visited,
    &:focus {
        color: ${darkGrey};  
    }
`;