import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { EnchancedComparisonScreen } from './ComparisonScreen';

function ConnectedComparisonScreen(props) {
  return <EnchancedComparisonScreen {...props} />;
}

function mapStateToProps({ applicationData, userCarPhoto, selectedRims, rimData, wheelsPosition, userCarPhotoWithNewWheels }) {
  return {
    applicationData,
    selectedRims,
    userCarPhoto,
    rimData,
    wheelsPosition,
    userCarPhotoWithNewWheels
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {},
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectedComparisonScreen);
