export const DeviceOrientationDetection = () => {
  switch (window.orientation) {
    case 0:
    case 180:
      return 'portrait';
    case 90:
    case -90:
      return 'landscape'
    default: return 'portrait';
  }
}