import React, { useEffect } from 'react';
import { ContainerLayout } from '../../helpers/styles/layout';
import { SubModelSelect } from './comoponents/SubModelSelect/SubModelSelect';

export const SubModelsScreen = ({ setSelectedSeries, match, setSelectedSubModel, applicationData, selectedSeries, history, removeSelectedSubModel }) => {
  useEffect(() => {
    removeSelectedSubModel();
    // history.push(`/models/${seriesId}/submodels`);
  }, []);
  const { seriesId } = match.params;

  useEffect(() => {
    console.log(applicationData)
    if (applicationData && applicationData.series && applicationData.series.length && Object.entries(applicationData).length) {
      const series = applicationData.series.find(item => item.id === seriesId);
      console.log(series)

      if (series) {
        setSelectedSeries(series);
      } else {
        // history.push('/models');
      }
    }
  }, [applicationData]);

  const onSubModelSelect = subModel => {
    setSelectedSubModel(subModel);
    history.push(`/models/${seriesId}/submodels/${subModel.id}/camera`);
  };

  return (
    <ContainerLayout>
      {selectedSeries && selectedSeries.submodels && (
        <SubModelSelect series={selectedSeries} subModels={selectedSeries.submodels} onSubModelSelect={onSubModelSelect} />
      )}
    </ContainerLayout>
  );
};
