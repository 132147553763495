import data from '../assets/mocks/api/latest.json';

const getApplicationDataActionType = 'GET_APPLICATION_DATA';
const getApplicationDataSuccessActionType = `${getApplicationDataActionType}_SUCCESS`;
const getApplicationDataFailureActionType = `${getApplicationDataActionType}_FAILURE`;

const getApplicationDataAction = () => ({
  type: getApplicationDataActionType
});

const getApplicationDataSuccessAction = payload => ({
  type: getApplicationDataSuccessActionType,
  payload
});

const applicationDataDTO = payload => {
  return {
    ...payload,
    series: payload.series.map(oneSeries => {
      return {
        ...oneSeries,
        submodels: oneSeries.models.reduce((acc, model) => [...acc, ...model.submodels], [])
      };
    })
  };
};

const getApplicationData = () => dispatch => {
  dispatch(getApplicationDataAction());
  dispatch(getApplicationDataSuccessAction(applicationDataDTO(data)));

  // const dataUrl = 'https://api.qm.innovation.rocks/audi/raederapp/';

  // return axios
  //   .get(dataUrl)
  //   .then(({ data }) => {
  //     dispatch(getApplicationDataSuccessAction(applicationDataDTO(data)));
  //     dispatch(setSelectedRim(data.rims[0]));
  //     dispatch(setSelectedRimSeason(setRimsSeason(data.rims[0].type)));
  //   })
  //   .catch(error => {
  //     dispatch(getApplicationDataFailureAction(error.data));
  //   });
};

export { getApplicationDataActionType, getApplicationDataSuccessActionType, getApplicationDataFailureActionType, getApplicationData };
