const setUserCarPhotoActionType = 'SET_USER_CAR_PHOTO';
const removeUserCarPhotoActionType = 'REMOVE_USER_CAR_PHOTO';

const setUserCarPhotoAction = (data) => ({
  type: setUserCarPhotoActionType,
  payload: data
});

const removeUserCarPhotoAction = () => ({
  type: removeUserCarPhotoActionType
});

const setUserCarPhoto = (data) => dispatch => {
  dispatch(setUserCarPhotoAction(data));
};

const removeUserCarPhoto = () => dispatch => {
  dispatch(removeUserCarPhotoAction());
};

export {
  setUserCarPhotoActionType,
  removeUserCarPhotoActionType,
  setUserCarPhoto,
  removeUserCarPhoto
}