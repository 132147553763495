import React from 'react';
import ReactDOM from 'react-dom';
import { addLocaleData, IntlProvider } from 'react-intl';
import en from 'react-intl/locale-data/en';
import de from 'react-intl/locale-data/de';
import { Provider } from 'react-redux';

// import * as serviceWorker from './serviceWorker';
import messagesDe from './assets/i18n/de.json';
import messagesEn from './assets/i18n/en.json';
import ConnectedApp from './ConnectedApp';
import store from './store';

import './polyfills';

addLocaleData(de);
addLocaleData(en);

const messages = {
  de: messagesDe,
  en: messagesEn
};

const language = 'de';

ReactDOM.render(
  <Provider store={store}>
    <IntlProvider locale={language} messages={messages[language]}>
      <ConnectedApp />
    </IntlProvider>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
