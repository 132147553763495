import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { isSafari } from 'react-device-detect';

import logo from '../../assets/img/logo.png';
import { MenuComponent } from '../MenuComponent/MenuComponent';
import { setApplicationStateNextLocation, changeApplicationBlockHistoryPush } from '../../actions/applicationState';
import { removeUserCarPhoto } from '../../actions/userCarPhoto';
import { darkGrey, headerFontColor, zIndexes, headerHeight } from '../../constants';

const HeaderWrapper = styled.div`
  position: fixed;
  z-index: ${zIndexes.header};
  display: flex;
  align-items: center;
  justify-content: space-between;
  top: 0;
  padding: 0 15px;
  height: ${headerHeight}px;
  width: 100%;
  color: #ffffff;
  background: ${darkGrey};
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.5);
  font-family: AudiTypeScreen;

  @media only screen and (max-width: 440px) {
    padding: 0 5px;
  }
`;

const HeaderItem = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  font-size: 12px;
  margin-left: 24px;

  &:first-child {
    margin-left: 22px;
  }

  &.is-active {
    padding-bottom: 1px;
    border-bottom: 2px solid #ffffff;

    button {
      color: #ffffff !important;

      span {
        color: #ffffff !important;
      }
    }
  }

  @media only screen and (max-width: 480px) {
    margin-left: 20px;
  }

  @media only screen and (max-width: 480px) {
    &:first-child {
      margin-left: 2%;
    }

    margin-left: 3%;
  }

  @media only screen and (max-width: 350px) {
    &:first-child {
      margin-left: 5px;
    }

    margin-left: 7px;

    button {
      font-size: 12px;
    }
  }
`;

const HeaderMenuButton = styled.button`
  padding: 0;
  background: transparent;
  border: none;
  text-decoration: none;
  margin-top: 5px;
  padding-bottom: 3px;
  color: #ffffff;
  outline: none;

  &:disabled {
    color: ${headerFontColor};

    span {
      color: ${headerFontColor};
    }
  }

  span {
    padding-left: 3px;
    color: #ffffff;
  }
`;

const HeaderMenu = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
  width: 100%;
`;

const HeaderLogo = styled.img`
  max-width: 43px;

  @media only screen and (max-width: 440px) {
    display: none;
  }
`;

const LinksContainer = styled.div`
  display: flex;

  @media only screen and (max-width: 440px) {
    width: 100%;
    overflow-x: scroll;
  }
`;

const HeaderComponent = ({ setModalType, setApplicationStateNextLocation, history, match, userCarPhoto, intl, removeUserCarPhoto }) => {
  const [applicationState, setState] = useState(null);
  const [locationName, setLocation] = useState(null);

  const setApplicationState = pathname => {
    switch (pathname) {
      case 'comparison':
        return 4;
      case 'pick-wheels':
        return 3;
      case 'wheels-positioning':
      case 'camera':
        return 2;
      case 'submodels':
      case 'models':
        return 1;
      default:
        return false;
    }
  };

  const checkState = () => {
    const pathnameArray = history.location.pathname.split('/').reverse();

    pathnameArray.some(value => {
      if (setApplicationState(value)) {
        setState(setApplicationState(value));
        setLocation(value);
        return true;
      }
      return false;
    });
  };

  const windowOnPopState = () => {
    window.onpopstate = () => {
      const promise = new Promise((resolve, reject) => {
        if (window.confirm(intl.formatMessage({ id: 'error-message-modal.message' }))) {
          resolve();
        } else {
          reject();
        }
      });

      promise
        .then(() => {
          if (history.location.pathname.split('/').reverse()[0] === 'camera') {
            removeUserCarPhoto();
          }
        })
        .catch(() => {
          window.onpopstate = undefined;
          history.go(1);
          // needs improvement
          setTimeout(() => {
            windowOnPopState();
          }, 100);
        });
    };
  };

  useEffect(() => {
    checkState();
  }, [history.location]);

  useEffect(() => {
    if (userCarPhoto) {
      switch (locationName) {
        case 'pick-wheels':
        case 'wheels-positioning': {
          if (!isSafari) {
            // window.onbeforeunload not supported Safari on iOS
            window.onbeforeunload = () => true;
            window.onpopstate = undefined;
          }
          break;
        }

        case 'comparison': {
          if (!isSafari) {
            // window.onbeforeunload not supported Safari on iOS
            window.onbeforeunload = () => true;
            windowOnPopState();
          }
          break;
        }
        default: {
          window.onbeforeunload = undefined;
          window.onpopstate = undefined;
          break;
        }
      }
    }
  }, [userCarPhoto, locationName]);

  const handleMenuClick = path => {
    if (userCarPhoto && history.location.pathname !== path) {
      switch (locationName) {
        case 'wheels-positioning':
        case 'pick-wheels':
        case 'comparison': {
          setApplicationStateNextLocation(path);
          return setModalType('Warning');
        }
        default:
          return history.push(path);
      }
    }
    return null;
  };

  return (
    <HeaderWrapper>
      <HeaderMenu>
        <MenuComponent setModalType={setModalType} />
        <LinksContainer>
          <HeaderItem className={applicationState === 1 && 'is-active'}>
            <HeaderMenuButton
              onClick={() => {
                handleMenuClick('/models');
              }}
              disabled={applicationState < 1}
            >
              1.
              <FormattedMessage id="header-option.model" />
            </HeaderMenuButton>
          </HeaderItem>
          <HeaderItem className={applicationState === 2 && 'is-active'}>
            <HeaderMenuButton
              onClick={() => handleMenuClick(`/models/${match.params.seriesId}/submodels/${match.params.subModelId}/camera`)}
              disabled={applicationState < 2}
            >
              2.
              <FormattedMessage id="header-option.camera" />
            </HeaderMenuButton>
          </HeaderItem>
          <HeaderItem className={applicationState === 3 && 'is-active'}>
            <HeaderMenuButton
              onClick={() => handleMenuClick(`/models/${match.params.seriesId}/submodels/${match.params.subModelId}/pick-wheels`)}
              disabled={applicationState < 3}
            >
              3.
              <FormattedMessage id="header-option.rims" />
            </HeaderMenuButton>
          </HeaderItem>
          <HeaderItem className={applicationState === 4 && 'is-active'}>
            <HeaderMenuButton
              onClick={() => handleMenuClick(`/models/${match.params.seriesId}/submodels/${match.params.subModelId}/comparison`)}
              disabled={applicationState < 4}
            >
              4.
              <FormattedMessage id="header-option.comparison" />
            </HeaderMenuButton>
          </HeaderItem>
        </LinksContainer>
      </HeaderMenu>
      <HeaderLogo src={logo} alt="audi logo" />
    </HeaderWrapper>
  );
};

function mapStateToProps({ applicationState, userCarPhoto }) {
  return {
    blockHistoryPush: applicationState.blockHistoryPush,
    userCarPhoto
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setApplicationStateNextLocation,
      changeApplicationBlockHistoryPush,
      removeUserCarPhoto
    },
    dispatch
  );
}

export default compose(
  withRouter,
  injectIntl,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(HeaderComponent);
