import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { zIndexes } from '../../constants';

const StyledImg = styled.img`
  position: absolute;
  z-index: ${zIndexes.stencil};
  width: 75%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) ${props => (props.isMirror ? 'scaleX(-1)' : '')};
`;

const formatImageUrl = url => {
  let formattedUrl = url;

  if (!formattedUrl.startsWith('/')) {
    formattedUrl = `/${formattedUrl}`;
  }
  if (!formattedUrl.startsWith('/thumbnails')) {
    formattedUrl = `/thumbnails${formattedUrl}`;
  }

  if (!formattedUrl.endsWith('.png')) {
    formattedUrl = `${formattedUrl}.png`;
  }
  return formattedUrl;
};

export function Stencil({ stencilUrl, stencilRef, ...props }) {
  const formattedUrl = formatImageUrl(stencilUrl);
  return <StyledImg src={formattedUrl} alt="stencil" {...props} ref={stencilRef} />;
}

Stencil.propTypes = {
  stencilUrl: PropTypes.string.isRequired,
  stencilRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }).isRequired,
  isMirror: PropTypes.bool.isRequired
};
