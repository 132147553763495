import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { PickWheelsScreen } from './PickWheelsScreen';
import { getApplicationData } from '../../actions/applicationData';
import { setWheelsPosition } from '../../actions/wheelsPosition';
import { setSelectedRim } from '../../actions/selectedRims';
import { setUserCarPhotoWithNewWheels } from '../../actions/userCarPhotoWithNewWheels';

function ConnectedPickWheelsScreen(props) {
  return <PickWheelsScreen {...props} />;
}

function mapStateToProps({ applicationData, selectedSubModel, userCarPhoto, wheelsPosition, selectedRims, userCarPhotoWithNewWheels }) {
  return {
    applicationData,
    selectedSubModel,
    userCarPhoto,
    wheelsPosition,
    selectedRims,
    userCarPhotoWithNewWheels
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getApplicationData,
      setWheelsPosition,
      setSelectedRim,
      setUserCarPhotoWithNewWheels
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectedPickWheelsScreen);
