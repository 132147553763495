import { getApplicationDataActionType } from '../actions/applicationData';

const initialState = {
  analytics: {},
  series: [],
  rims: []
};

const applicationData = (state = initialState, action) => {
  switch (action.type) {
    case `${getApplicationDataActionType}_SUCCESS`: {
      const filteredSeries = action.payload.series.map(item => ({
        ...item,
        submodels: item.submodels
          // Removing of extra models, which can't be removed from JSON and submodels, elder than 2016
          // Client asked to remove this rule, so it stays commented
          // .filter(
          //   sub =>
          //     (item.name === 'A3' && sub.name.slice(0, 2) === 'S3') ||
          //     (item.name === 'A6' && sub.name.slice(0, 2) !== 'S6' && sub.name.slice(-4) === '(C7)') ||
          //     +sub.id.slice(-4) >= 2016
          // )
        // Removing of extra models, which can't be removed from JSON - end
      }));

      // Plug for BUG AUDI-108
      ['4', '6'].forEach(model => {
        const index = filteredSeries.findIndex(item => item.id === `MO_AU_AA${model}R`);
        if (index === -1) {
          return;
        }
        const main = filteredSeries.findIndex(item => item.id === `MO_AU_AA${model}`);
        const quatro = filteredSeries.splice(index, 1)[0].submodels[0];
        filteredSeries[main].submodels.push(quatro);
      });
      // Plug for BUG AUDI-108 end

      // filter for models and submodels with no rims
      const rimFilteredSeries = filteredSeries.map(parentModel => {
        const filteredParent = parentModel.models.map(mod => {
          const filteredSub = mod.submodels.filter(sub => !!sub.rims[0].id);
          return { ...mod, submodels: filteredSub };
        });
        return { ...parentModel, models: filteredParent };
      });
      const resSeries = rimFilteredSeries.map(item => {
        const filteredSub = item.submodels.filter(sub => !!sub.rims[0].id);
        return { ...item, submodels: filteredSub };
      });

      return {
        ...state,
        analytics: action.payload.analytics,
        series: resSeries,
        rims: action.payload.rims
      };
    }

    default:
      return state;
  }
};

export default applicationData;
