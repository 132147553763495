// Colors
export const lightGrey = '#F2F2F2';
export const grey = '#F5F5F5';
export const darkGrey = '#333333';
export const mediumGrey = '#818285FF';
export const headerFontColor = '#959595';
export const tableBackgroundGrey = '#E7E9EA';
export const red = '#BB0A30';

export const headerHeight = 40;

export const zIndexes = {
  alert: 7,
  modal: 6,
  header: 5,
  tooltip: 5,
  stencil: 4,
  cameraButtons: 3,
  cameraPreview: 2,
  camera: 1
};
