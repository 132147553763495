import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { StyledWebcamContainer } from '../../helpers/styles/layout';

const StyledContainer = styled.div`
  background-color: black;
`;

const StyledImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export function ImageWrapper({ imageSrc, children }) {
  return (
    <StyledContainer>
      <StyledWebcamContainer>
        <StyledImg src={imageSrc} />
      </StyledWebcamContainer>
      {children}
    </StyledContainer>
  );
}

ImageWrapper.propTypes = {
  imageSrc: PropTypes.string.isRequired
};
