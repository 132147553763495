import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import cancelIcon from '../../assets/ico/cancel-white.svg';
import { darkGrey } from '../../constants';
import packageJson from '../../../package.json';

const HamburgerIcon = styled.div`
  width: 18px;
  height: 10px;

  span {
    display: block;
    height: 1px;
    background: white;

    &:not(:last-child) {
      margin-bottom: 3px;
    }
  }
`;

const SideBar = styled.div`
  position: fixed;
  top: 0;
  left: -190px;
  width: 190px;
  height: 100%;
  transition: left 0.4s ease-in;
  background: ${darkGrey};

  &.is-visible {
    left: 0;
  }
`;

const SideBarOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transform: translate3d(100%, 0px, 0px);
  transition: opacity 0.4s ease-in, transform 0s ease 0.4s;

  &.is-visible {
    opacity: 1;
    transform: none;
    transition: opacity 0.4s ease;
  }
`;

const MenuList = styled.ul`
  padding: 40px 30px 0 30px;
  list-style-type: none;

  li {
    display: block;
    padding: 10px 0;
    color: white;
    text-decoration: none;
    border-bottom: 1px solid rgba(179, 179, 179, 0.1);
  }
`;

const MenuClose = styled.div`
  position: absolute;
  right: 0;
  width: 48px;
  height: 48px;
`;

export const MenuComponent = () => {
  const [showSideBar, toggleSideBar] = useState(false);
  return (
    <React.Fragment>
      <HamburgerIcon onClick={() => toggleSideBar(true)}>
        <span />
        <span />
        <span />
      </HamburgerIcon>
      <SideBarOverlay onClick={() => toggleSideBar(false)} className={showSideBar && 'is-visible'} />
      <SideBar className={showSideBar && 'is-visible'}>
        <MenuClose onClick={() => toggleSideBar()}>
          <img src={cancelIcon} alt="close" />
        </MenuClose>
        <MenuList>
          <li onClick={() => window.open('https://www.audi.de/de/brand/de.html#layer=/de/brand/de/tools/navigation/layer/impressum.html', '_blank')}>
            <FormattedMessage id="menu-option.imprint" />
          </li>
          <li onClick={() => window.open('https://www.audi.de/de/brand/de.html#layer=/de/brand/de/tools/navigation/layer/rechtliches.html', '_blank')}>
            <FormattedMessage id="menu-option.law" />
          </li>
        </MenuList>

        <MenuList>
          <li>Version: {packageJson.version}</li>
        </MenuList>
      </SideBar>
    </React.Fragment>
  );
};
