import React, { useEffect } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
// import deviceHint from '../assets/images/screenshotEntryPage.png'
import closeIcon from '../assets/ico/cancel-dark.svg';
import { zIndexes } from '../constants';

const ModalWrapper = styled.div`
  position: fixed;
  z-index: ${zIndexes.modal};
  top: 0;
  left: 0;
  width 100%;
  min-height: 100%;
  background:url(deviceHint)
`;

const ModalClose = styled.div`
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  width: 48px;
  height: 48px;
`;

const ModalBody = styled.div`
  position: absolute;
  top: 45px;
  bottom: 10px;
  left: 15px;
  right: 15px;
  overflow-y: scroll;
`;

const ModalComponent = ({ children, setModalType, history,isBadDevice }) => {
  useEffect(() => {
    const unblock = history.block(() => false);

    return () => unblock();
  }, []);
  return (
    <ModalWrapper>
      {!isBadDevice?<ModalClose onClick={() => setModalType('')}>
        <img src={closeIcon} alt="close" />
      </ModalClose>:<p></p>}
      <ModalBody>{children}</ModalBody>
    </ModalWrapper>
  );
};

export default withRouter(ModalComponent);
