import React, { useEffect } from 'react';

import { PickWheels } from './components/PickWheels';
import { loadImgages } from '../../helpers/multipleImagesLoading';
import { cameraImageSize } from '../../constants/appConstants';

export const PickWheelsScreen = ({
  applicationData,
  userCarPhoto,
  wheelsPosition,
  selectedSubModel,
  setSelectedRim,
  selectedRims,
  season,
  rimSize,
  history,
  match,
  setSelectedRimSize,
  setSelectedRimSeason,
  setUserCarPhotoWithNewWheels,
  userCarPhotoWithNewWheels
}) => {
  const { seriesId, subModelId } = match.params;

  useEffect(() => {
    if (!userCarPhoto.base64 || !wheelsPosition) {
      history.push(`/models/${seriesId}/submodels/${subModelId}/camera`);
    }
  }, [userCarPhoto, wheelsPosition]);

  const setUserCarPhoto = images => {
    // Get images
    const wheelImage = images.find(image => image.path === selectedRims.thumbnail).img;
    const userCarPhotoImage = images.find(image => image.path === userCarPhoto.base64).img;

    // Create offscreen canvas to hold full image with new wheels
    const offscreenCanvas = document.createElement('canvas');
    offscreenCanvas.width = cameraImageSize.width;
    offscreenCanvas.height = cameraImageSize.height;

    const offscreenContext = offscreenCanvas.getContext('2d');
    offscreenContext.imageSmoothingEnabled = true;
    offscreenContext.imageSmoothingQuality = 'high';

    const { radius, frontPositionX, frontPositionY, rearPositionX, rearPositionY } = wheelsPosition.fullImage;

    offscreenContext.drawImage(userCarPhotoImage, 0, 0);

    // add black background for wheels
    offscreenContext.beginPath();
    offscreenContext.arc(frontPositionX, frontPositionY, radius, 0, 2 * Math.PI);
    offscreenContext.fillStyle = 'black';
    offscreenContext.fill();
    offscreenContext.stroke();

    offscreenContext.beginPath();
    offscreenContext.arc(rearPositionX, rearPositionY, radius, 0, 2 * Math.PI);
    offscreenContext.fillStyle = 'black';
    offscreenContext.fill();
    offscreenContext.stroke();

    // draw rims
    offscreenContext.drawImage(wheelImage, frontPositionX - radius, frontPositionY - radius, 2 * radius, 2 * radius);
    offscreenContext.drawImage(wheelImage, rearPositionX - radius, rearPositionY - radius, 2 * radius, 2 * radius);

    const withWheelsImageData = offscreenCanvas.toDataURL();

    setUserCarPhotoWithNewWheels(withWheelsImageData);

    return Promise.resolve();
  };

  const onConfirm = () => {
    loadImgages([userCarPhoto.base64, selectedRims.thumbnail])
      .then(setUserCarPhoto)
      // eslint-disable-next-line no-console
      .catch(console.error)
      .then(() => {
        history.push(`/models/${seriesId}/submodels/${subModelId}/comparison`);
      })
      // eslint-disable-next-line no-console
      .catch(console.error)
      
  };

  return (
    <div>
      {userCarPhoto && (
        <PickWheels
          onConfirm={onConfirm}
          wheelsPosition={wheelsPosition}
          selectedSubModel={selectedSubModel}
          applicationData={applicationData}
          setSelectedRim={setSelectedRim}
          setSelectedRimSize={setSelectedRimSize}
          setSelectedRimSeason={setSelectedRimSeason}
          userCarPhotoWithNewWheels={userCarPhotoWithNewWheels}
          setUserCarPhotoWithNewWheels={setUserCarPhotoWithNewWheels}
          userCarPhoto={userCarPhoto}
          selectedRims={selectedRims}
          season={season}
          rimSize={rimSize}
        />
      )}
    </div>
  );
}
