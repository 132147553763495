/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import styled from 'styled-components';

const List = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;

  li {
    display: block;
    padding: 10px 0;
    color: rgba(0, 0, 0, 0.5);
    font-size: 14px;
    text-decoration: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    &:last-child {
      border-bottom: none;
    }

    &.is-selected {
      color: #000000;
    }
  }
`;

export const ListComponent = ({ items, onItemClick, selectedValue, toggleVisibility, rimsList = false }) => {
  return (
    <List>
      {items.map(value => (
        <li
          className={value === selectedValue ? 'is-selected' : ''}
          onClick={() => {
            onItemClick(value);
            setTimeout(() => {
              toggleVisibility(false);
            }, 100);
          }}
          key={value}
        >
          {rimsList ? `${value}''` : value}
        </li>
      ))}
    </List>
  );
};
