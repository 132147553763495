export const calculateRatio = (sourceWidth, sourceHeight, imageWidth, imageHeight) => {
  const hRatio = sourceWidth / imageWidth;
  const vRatio = sourceHeight / imageHeight;
  const ratio = Math.max(hRatio, vRatio);

  return ratio;
};

export const calculateDrawPositionForContainMode = (sourceWidth, sourceHeight, imageWidth, imageHeight) => {
  const ratio = calculateRatio(sourceWidth, sourceHeight, imageWidth, imageHeight);

  const centerShiftX = ( sourceWidth - (imageWidth * ratio) ) / 2;
  const centerShiftY = ( sourceHeight - (imageHeight * ratio) ) / 2;

  return {
    ratio,
    centerShiftX,
    centerShiftY
  }
};