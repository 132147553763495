import React, { useEffect } from 'react';

import { ContainerLayout } from '../../helpers/styles/layout';

import { SeriesSelect } from './comoponents/SeriesSelect/SeriesSelect';

export const ModelsScreen = ({ setSelectedSeries, applicationData, history, removeSelectedSubModel }) => {
  const { series } = applicationData;

  useEffect(() => {
    removeSelectedSubModel();
  }, []);

  const onSeriesSelect = selectedSeries => {
    setSelectedSeries(selectedSeries);
    history.push(`/models/${selectedSeries.id}/submodels`);
  };

  return (
    <ContainerLayout>
      <SeriesSelect series={series} onSeriesSelect={onSeriesSelect} />
    </ContainerLayout>
  );
};
