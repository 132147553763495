const setSelectedSubModelActionType = 'SET_SELECTED_SUBMODEL';
const removeSelectedSubModelActionType = 'REMOVE_SELECTED_SUBMODEL';

const setSelectedSubModelAction = (data) => ({
  type: setSelectedSubModelActionType,
  payload: data
});

const removeSelectedSubModelAction = () => ({
  type: removeSelectedSubModelActionType
});

const setSelectedSubModel = (data) => dispatch => {
  dispatch(setSelectedSubModelAction(data));
};

const removeSelectedSubModel = () => dispatch => {
  dispatch(removeSelectedSubModelAction());
};

export {
  setSelectedSubModel,
  removeSelectedSubModel,
  setSelectedSubModelActionType,
  removeSelectedSubModelActionType
}