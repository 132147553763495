import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { CameraButton } from '../../../../components';
import { ReactComponent as ConfirmSvg } from '../../../../assets/images/checkmark_icon.svg';
import { ReactComponent as ResetSvg } from '../../../../assets/images/reset_icon.svg';
import { InfoBottomBar } from '../../../../components/BottomBar';
import { red } from '../../../../constants';
import PositionSvg from '../../../../assets/images/position.svg';
import { ImageWrapper } from '../../../../components/ImageWrapper';

const StyledConfirmSvgButton = styled(CameraButton)`
  right: 10px;
  top: calc(50% - 30px);
`;

const StyledResetSvgButton = styled(CameraButton)`
  right: 10px;
  top: calc(50% + 30px);
`;

const StyledContainer = styled.div`
  background-color: black;
`;

const StyledWheelsContainer = styled.div`
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: absolute;
  z-index: 3;
`;

let wheels = [];

export function WheelsPositioning(props) {
  const { imageSrc, width, height, onReset, onConfirm, wheelsPosition } = props;
  const containerRef = useRef(null);
  const canvasWheelsRef = useRef(null);
  const { frontPositionX, frontPositionY, rearPositionX, rearPositionY, radius } = wheelsPosition.deviceSizeImage;

  let mouseStartPosition = {
    x: 0,
    y: 0
  };

  const positionIconImage = new Image();
  let dragok = false;

  // Initalize wheels with default values
  wheels.length = 0;
  wheels.push({
    name: 'front',
    x: frontPositionX,
    y: frontPositionY,
    radius,
    isDragging: false
  });
  wheels.push({
    name: 'rear',
    x: rearPositionX,
    y: rearPositionY,
    radius,
    isDragging: false
  });

  function drawWheels() {
    if (canvasWheelsRef.current) {
      const context = canvasWheelsRef.current.getContext('2d');

      // Clear scene
      context.clearRect(0, 0, canvasWheelsRef.current.width, canvasWheelsRef.current.height);

      const clientH = document.documentElement.clientHeight;

      const transformNavBar = height - clientH > 0 ? (height - clientH) / 2 : 0;
      wheels.forEach(wheel => {
        // Draw wheel ring
        context.beginPath();
        context.arc(wheel.x, wheel.y - transformNavBar, wheel.radius, 0, 2 * Math.PI);
        context.strokeStyle = red;
        context.lineWidth = 2;
        context.stroke();

        // Draw center cross
        context.lineWidth = 1;
        context.moveTo(wheel.x - 5, wheel.y - transformNavBar);
        context.lineTo(wheel.x + 5, wheel.y - transformNavBar);
        context.stroke();
        context.moveTo(wheel.x, wheel.y - transformNavBar - 5);
        context.lineTo(wheel.x, wheel.y - transformNavBar + 5);
        context.stroke();

        // Draw positioning icon
        context.drawImage(positionIconImage, wheel.x + wheel.radius / 2, wheel.y - transformNavBar - wheel.radius);
      });
    }
  }

  function myDown(e) {
    // get the current mouse position
    const mx = e.changedTouches[0].clientX;
    let my = e.changedTouches[0].clientY;

    // Fix y mouse position on ios, when toolbar is collapsed
    my -= canvasWheelsRef.current.getBoundingClientRect().top;

    dragok = false;
    wheels = wheels.map(wheel => {
      let { isDragging } = wheel;

      // Test if mouse is in position icon
      if (!dragok && mx > wheel.x && mx < wheel.x + 2 * wheel.radius && my < wheel.y && my > wheel.y - 2 * wheel.radius) {
        e.preventDefault();
        e.stopPropagation();
        isDragging = true;
        dragok = true;
      }

      return {
        ...wheel,
        isDragging
      };
    });

    // Save the current mouse position
    mouseStartPosition = {
      x: mx,
      y: my
    };
  }

  function myUp() {
    // Clear all the dragging and resizing flags
    dragok = false;
    wheels = wheels.map(wheel => {
      return {
        ...wheel,
        isDragging: false
      };
    });
  }

  function myMove(e) {
    if (dragok) {
      // Get the current mouse position
      const mx = e.changedTouches[0].clientX;
      let my = e.changedTouches[0].clientY;

      // Fix y mouse position on ios, when toolbar is collapsed
      my -= canvasWheelsRef.current.getBoundingClientRect().top;

      // Calculate the distance the mouse has moved since the last mousemove
      const dx = mx - mouseStartPosition.x;
      const dy = my - mouseStartPosition.y;

      wheels = wheels.map(wheel => {
        let { x, y } = wheel;
        if (wheel.isDragging) {
          x += dx;
          y += dy;
        }
        x = Math.min(width - radius - 70, Math.max(radius, x));
        y = Math.min(height - 70, Math.max(40 + radius, y));

        return {
          ...wheel,
          x,
          y
        };
      });

      drawWheels();

      mouseStartPosition = {
        x: mx,
        y: my
      };
    }
  }

  useEffect(() => {
    positionIconImage.onload = async () => {
      drawWheels();
    };

    positionIconImage.src = PositionSvg;

    canvasWheelsRef.current.ontouchstart = myDown;
    canvasWheelsRef.current.ontouchend = myUp;
    canvasWheelsRef.current.ontouchmove = myMove;
  }, [imageSrc, width, height]);

  return (
    <StyledContainer ref={containerRef}>
      <ImageWrapper imageSrc={imageSrc} width={width} height={height}>
        <StyledWheelsContainer>
          <canvas width={width} height={height} ref={canvasWheelsRef} />
        </StyledWheelsContainer>
        <StyledConfirmSvgButton onClick={() => onConfirm(wheels)} className="TakePhotoButton">
          <ConfirmSvg />
        </StyledConfirmSvgButton>
        <StyledResetSvgButton onClick={onReset} className="FlipStencilButton">
          <ResetSvg />
        </StyledResetSvgButton>

        <InfoBottomBar>
          <FormattedMessage id="camera-screen.wheel-positioning.info-text" />
        </InfoBottomBar>
      </ImageWrapper>
    </StyledContainer>
  );
}

WheelsPositioning.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  imageSrc: PropTypes.string.isRequired,
  onReset: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired
};
