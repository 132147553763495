import React, { useEffect, useState } from 'react';

import { CarPhotoCapture } from './components/CarPhotoCapture';
import { withDeviceSize } from '../../helpers/withDeviceSize';

export function CameraScreen(props) {
  const {
    applicationData,
    width,
    height,
    selectedSubModel,
    setUserCarPhoto,
    setWheelsPosition,
    history,
    match,
    setSelectedSeries,
    setSelectedSubModel
  } = props;
  const { seriesId, subModelId } = match.params;
  const [dataIsValid, setDataIsValid] = useState(false);

  useEffect(() => {
    if (applicationData && applicationData.series && applicationData.series.length && Object.entries(applicationData).length) {
      const series = applicationData.series.find(item => item.id === seriesId);
      const subModel = series && series.submodels.find(item => item.id === subModelId);

      if (series && subModel) {
        setSelectedSeries(series);
        setSelectedSubModel(subModel);
        setDataIsValid(true);
      } else {
        history.push(`/models/${seriesId}/submodels`);
      }
    }
  }, [applicationData]);

  const onPhotoCapture = (photoSrc, positionData) => {
    setUserCarPhoto(photoSrc);
    setWheelsPosition(positionData);
    history.push(`/models/${seriesId}/submodels/${subModelId}/wheels-positioning`);
  };

  return (
    <div>
      {dataIsValid && selectedSubModel && <CarPhotoCapture width={width} height={height} onPhotoCapture={onPhotoCapture} selectedSubModel={selectedSubModel} />}
    </div>
  );
}

export const EnchancedCameraScreen = withDeviceSize(CameraScreen);
