import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import deviceHint from '../../assets/images/incompatible.png';
import { darkGrey, lightGrey } from '../../constants';

const WarningMessageWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: none;
`;

const WarningMessage = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 22em;
  width: 34em;
  position: relative;
  padding: 2em;
  background-color: ${lightGrey};

  span {
    color: black;
    font-size: 18px;
    letter-spacing: -0.2px;
    line-height: 20px;
  }
`;

const WrapperImage = styled.div`
  margin-left: 12.5em;
`;

const Message = styled.div`
  max-width: 576px;
  text-align: center;
  font-size: 16pt;
  font-weight: 300;
  line-height: 24pt;
  color: ${darkGrey};

  @media (max-height: 400px) {
    margin: 25px 0;
  }
`;

export const BadDeviceMessageComponent = () => {
  return (
    <WarningMessageWrapper>
      <WarningMessage>
        <WrapperImage>
          <img src={deviceHint} alt="bad device hint" />
        </WrapperImage>
        <Message>
          <FormattedMessage id="bad-device-screen.message" />
        </Message>
      </WarningMessage>
    </WarningMessageWrapper>
  );
};
