import React from 'react';
import PropTypes from 'prop-types';

import { Tile, TilesContainer, TypePanel, TypePanelHeader } from '../../../../components/Tile/Tile';

export const SubModelSelect = ({ series, subModels, onSubModelSelect }) => {
  const groupTypes = ['Basismodell', 'Coupe', 'Sportback', 'Roadster', 'Limousine', 'Lang-Version','Limousine g-tron', 'Cabrio', 'Avant', 'Allroad'];
  const groupNameHandler = name => {
    return name === 'Cabrio' ? 'Cabriolet' : name;
  };

  const groupedSubModels = groupTypes.map(item => {
    return subModels.filter(subModel => subModel.group === item);
  });

  const orderSubModels = groupedSubModels.map(submodel => {
    if (submodel.length === 0) return submodel
    return submodel.sort((a,b) => {
      if (+a['motorization-order'] !== +b['motorization-order']){
        return (+a['motorization-order'] - +b['motorization-order'])
      }
        return  +a.id.slice(-4)- +b.id.slice(-4)
    })
  })

  const formatImageUrl = url => {
    let formattedUrl = url;

    if (!formattedUrl.startsWith('/')) {
      formattedUrl = `/${formattedUrl}`;
    }
    if (!formattedUrl.startsWith('/thumbnails')) {
      formattedUrl = `/thumbnails${formattedUrl}`;
    }

    if (!formattedUrl.endsWith('.png')) {
      formattedUrl = `${formattedUrl}.png`;
    }
    return formattedUrl;
  };

  return (
    <TilesContainer>
      {subModels &&
        orderSubModels.map(
          subType =>
            subType.length > 0 && (
              <TypePanel key={subType[0].group}>
                <TypePanelHeader>{groupNameHandler(subType[0].group)}</TypePanelHeader>
                {subType.map(subModel => (
                  <Tile
                    key={subModel.id}
                    thumbnail={formatImageUrl(subModel.thumbnail)}
                    altText={subModel.name}
                    text={series.name}
                    subheader={subModel.name}
                    onTileClick={() => onSubModelSelect(subModel)}
                  />
                ))}
              </TypePanel>
            )
        )}
    </TilesContainer>
  );
};

export const RimPropTypes = PropTypes.shape({
  id: PropTypes.string.isRequired
});

export const SubModelPropTypes = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  stencil: PropTypes.string.isRequired,
  'coordinates-front-x': PropTypes.string.isRequired,
  'coordinates-front-y': PropTypes.string.isRequired,
  'coordinates-rear-x': PropTypes.string.isRequired,
  'coordinates-rear-y': PropTypes.string.isRequired,
  thumbnail: PropTypes.string.isRequired,
  rims: PropTypes.arrayOf(RimPropTypes)
});

SubModelSelect.propTypes = {
  subModels: PropTypes.arrayOf(SubModelPropTypes).isRequired,
  onSubModelSelect: PropTypes.func.isRequired
};
