import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { EnchancedWheelsPositioningScreen } from './WheelsPositioningScreen';
import { getApplicationData } from '../../actions/applicationData';
import { setUserCarPhoto, removeUserCarPhoto } from '../../actions/userCarPhoto';
import { setWheelsPosition, removeWheelsPosition } from '../../actions/wheelsPosition';

function ConnectedWheelsPositioningScreen(props) {
  return <EnchancedWheelsPositioningScreen {...props} />;
}

function mapStateToProps({
  applicationData,
  selectedSubModel,
  userCarPhoto,
  wheelsPosition
}) {
  return {
    applicationData,
    selectedSubModel,
    userCarPhoto,
    wheelsPosition
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getApplicationData,
      setUserCarPhoto,
      removeUserCarPhoto,
      setWheelsPosition,
      removeWheelsPosition
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectedWheelsPositioningScreen);
