import React from 'react';
import styled from "styled-components";

import { darkGrey } from '../constants/styleConstants';

const Button = styled.button`
  width: 100px;
  height: 40px;
  background: ${props => props.primary ? darkGrey : '#FFFFFF'};
  border: 1px solid #FFFFFF;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  font-family: AudiTypeScreen;

  span {
    color: ${props => props.primary ? '#FFFFFF' : '#000000'}; 
  }
`;

export const ButtonComponent = ({ children, primary, onClick }) => (
  <Button onClick={onClick} primary={primary || null}>{children}</Button>
);


