const setSelectedModelActionType = 'SET_SELECTED_MODEL';
const removeSelectedModelActionType = 'REMOVE_SELECTED_MODEL';

const setSelectedModelAction = (data) => ({
  type: setSelectedModelActionType,
  payload: data
});

const removeSelectedModelAction = () => ({
  type: removeSelectedModelActionType
})

const setSelectedModel = (data) => dispatch => {
  dispatch(setSelectedModelAction(data));
};

const removeSelectedModel = () => dispatch => {
  dispatch(removeSelectedModelAction())
}

export {
  setSelectedModel,
  removeSelectedModel,
  setSelectedModelActionType,
  removeSelectedModelActionType
}