const setWheelsPositionActionType = 'SET_WHEELS_POSITION';
const resetWheelsPositionActionType = 'REMOVE_WHEELS_POSITION';

const setWheelsPositionAction = (data) => ({
  type: setWheelsPositionActionType,
  payload: data
});

const removeWheelsPositionAction = () => ({
  type: resetWheelsPositionActionType
});

const setWheelsPosition = (data) => dispatch => {
  dispatch(setWheelsPositionAction(data));
};

const removeWheelsPosition = () => dispatch => {
  dispatch(removeWheelsPositionAction());
};

export {
  setWheelsPositionActionType,
  resetWheelsPositionActionType,
  removeWheelsPosition,
  setWheelsPosition
}