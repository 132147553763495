import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';

import { CameraButton, RimsMenuComponent } from '../../../../components';
import { ReactComponent as ConfirmSvg } from '../../../../assets/images/checkmark_icon.svg';
import { BottomBar } from '../../../../components/BottomBar/BottomBar';
import { ImageWrapper } from '../../../../components/ImageWrapper/ImageWrapper';
import { setRimsSeason } from '../../../../helpers';
import { cameraImageSize } from '../../../../constants/appConstants';
import { HorizontalScrollBox } from '../../../../components/HorizontalScrollBox';
import { darkGrey, mediumGrey } from '../../../../constants';

const StyledConfirmSvgButton = styled(CameraButton)`
  right: 10px;
  top: calc(50%);
`;

const StyledContainer = styled.div`
  background-color: black;
`;

const RimsContainer = styled.div`
  flex: 1;
  height: 55px;
  top: 0;
  width: 100%;
  position: relative;
  border: 1px solid white;
  border-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), white) 1 100%;
  border-right: 0;
`;

const rimMargin = 5

const RimButton = styled.button`
  padding: 0;
  margin: ${rimMargin}px;
  position: relative;
  top: ${props => (props.selected ? 0 : 10)}px;
  transition: top 0.1s;
`;

const rimSize = 54;

const RimImg = styled.img`
  width: ${rimSize}px;
  height: ${rimSize}px;
`;

const StyledBottomBar = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  height: 100%;
`;

const checkThumbnail = async rim => {
  try {
    const response = await fetch(rim.thumbnail);
    if (response.ok) {
      const file = await response.blob();
      return file.type === 'application/json' || file.type === 'image/png' || file.type === 'image/jpeg';
    }
    return false;
  } catch (e) {
    return false;
  }
};

function PickWheels({ applicationData, onConfirm, wheelsPosition, selectedSubModel, selectedRims, setSelectedRim, userCarPhoto, intl }) {
  // Array of rims which are in submodel
  const [subModelRimsData, setSubModelRimsData] = useState([]);
  // Slider rims
  const [rims, setRims] = useState([]);
  // Define current selected options in menu
  const [selectedSeason, setSelectedSeason] = useState(null);
  const [selectedSize, setSelectedSize] = useState(null);

  const [availablesSeasonsAndSizes, setAvailableSeasonsAndSizes] = useState([]);
  const [wheelsImageWrapperSrc, setWheelsImageWrapperSrc] = useState(null);
  const [applyFilter, setApplyFilter] = useState(
    /** @type {'weather' | 'rimSize' | 'none'} */('none'),
  );

  const setUserCarPhoto = (wheelImage, wheelsPos, background = 'dark') => {
    // Create offscreen canvas to hold new wheels
    const offscreenCanvas = document.createElement('canvas');
    offscreenCanvas.width = cameraImageSize.width;
    offscreenCanvas.height = cameraImageSize.height;

    const offscreenContext = offscreenCanvas.getContext('2d');
    offscreenContext.imageSmoothingEnabled = true;
    offscreenContext.imageSmoothingQuality = 'high';

    const { radius, frontPositionX, frontPositionY, rearPositionX, rearPositionY } = wheelsPos;

    // add background for wheels
    offscreenContext.beginPath();
    offscreenContext.arc(frontPositionX, frontPositionY, radius, 0, 2 * Math.PI);
    offscreenContext.fillStyle = background === 'dark' ? darkGrey : mediumGrey;
    offscreenContext.fill();
    offscreenContext.stroke();

    offscreenContext.beginPath();
    offscreenContext.arc(rearPositionX, rearPositionY, radius, 0, 2 * Math.PI);
    offscreenContext.fillStyle = background === 'dark' ? darkGrey : mediumGrey;
    offscreenContext.fill();
    offscreenContext.stroke();

    // draw rims
    offscreenContext.drawImage(wheelImage, frontPositionX - radius, frontPositionY - radius, 2 * radius, 2 * radius);
    offscreenContext.drawImage(wheelImage, rearPositionX - radius, rearPositionY - radius, 2 * radius, 2 * radius);

    const withWheelsImageData = offscreenCanvas.toDataURL();

    setWheelsImageWrapperSrc(withWheelsImageData);
  };

  const drawRims = rim => {
    if (!rim || !rim.thumbnail) {
      return false;
    }

    const rimImage = new Image();
    rimImage.crossOrigin = 'Anonymous';

    rimImage.onload = () => {
      setUserCarPhoto(rimImage, wheelsPosition.fullImage, rim.info.rim_background);
    };

    rimImage.src = rim.thumbnail;
    return true;
  };

  const getAvailableSizesAndSeasons = () => {
    // Get available seasons from subModel rims
    const availableSeasons = subModelRimsData.reduce((acc, subModelRim) => acc.add(setRimsSeason(subModelRim.info.tyre_type, intl)), new Set());
    const returnArray = [];

    // Assign sizes for each season and push those collections into array
    [...availableSeasons].forEach(availableSeason => {
      const availableSizesInGivenSeason = subModelRimsData.reduce((acc, subModelRim) => {
        if (subModelRim.info.rim_diameter && availableSeason === setRimsSeason(subModelRim.info.tyre_type, intl)) {
          return acc.add(+subModelRim.info.rim_diameter);
        }
        return acc;
      }, new Set());
      returnArray.push({ season: availableSeason, availableRimsSizes: [...availableSizesInGivenSeason] });
    });

    return returnArray;
  };

  const getAvailableRims = async () => {
    const subModelRims = applicationData.rims.filter(applicationRim => selectedSubModel.rims.some(subModelRim => applicationRim.id === subModelRim.id));
    const checkedRims = [];
    await Promise.all(
      subModelRims.map(async rim => {
        const check = await checkThumbnail(rim);
        if (check) {
          checkedRims.push(rim);
        }
      })
    );
    const res = subModelRims.filter(rim => checkedRims.some(checkedRim => checkedRim.id === rim.id));
    return res;
  };

  const getRimsForSeasonAndSize = () => {
    subModelRimsData.forEach(item => setRimsSeason(item.info.tyre_type, intl));
    // Don't apply any filters until user selected one
    switch (applyFilter) {
      case 'none':
        return subModelRimsData;
      case 'weather':
        return subModelRimsData.filter(
          subModelRim => setRimsSeason(subModelRim.info.tyre_type, intl) === selectedSeason
        );
      default:
        return subModelRimsData.filter(
          subModelRim => (
            setRimsSeason(subModelRim.info.tyre_type, intl) === selectedSeason
            && +subModelRim.info.rim_diameter === selectedSize
          )
        );
    }
  };

  /** This makes the last rim in the rims selector be a little bit more
   * to the right, so it doesn't get covered by the left ">" button
   */
  const LastRimPad = styled.div`
    width: 25px;
  `

  useEffect(() => {
    // Set available seasons and sizes
    setAvailableSeasonsAndSizes(getAvailableSizesAndSeasons());
  }, [subModelRimsData]);

  useEffect(() => {
    // Calculate available rims for given submodel
    const getSubModelRimsData = async () => {
      const availableRims = await getAvailableRims();
      setSubModelRimsData(availableRims);
    };
    getSubModelRimsData();
  }, []);

  useEffect(() => {
    // Draw selected rim
    if (selectedRims) {
      drawRims(selectedRims);
    }
  }, [selectedRims]);

  useEffect(() => {
    if (applicationData.rims) {
      setRims(getRimsForSeasonAndSize());
    }
  }, [subModelRimsData, selectedSeason, selectedSize]);

  useEffect(() => {
    // If there is no sizes for current season, the first size available for season is chosen
    const sizesForSeason = availablesSeasonsAndSizes.find(item => item.season === selectedSeason);
    if (!sizesForSeason) {
      return;
    }
    const sizesForSeasonArray = sizesForSeason ? sizesForSeason.availableRimsSizes : [];
    if (sizesForSeasonArray.indexOf(selectedSize) === -1) {
      setSelectedSize(sizesForSeasonArray[0]);
    }
  }, [selectedSeason]);

  useEffect(() => {
    // If there is no selected rim or rim do not exist in subModels rims array then set first rim in array
    // otherwise set seasons and sizes for given selected rim
    if (subModelRimsData && subModelRimsData.length && (!selectedRims || !subModelRimsData.some(rim => rim.id === selectedRims.id))) {
      setSelectedRim(subModelRimsData[0] || null);
      setSelectedSize(+subModelRimsData[0].info.rim_diameter);
      setSelectedSeason(setRimsSeason(subModelRimsData[0].info.tyre_type, intl));
    } else if (selectedRims && selectedRims.info.rim_diameter && selectedRims.info.tyre_type) {
      setSelectedSize(+selectedRims.info.rim_diameter);
      setSelectedSeason(setRimsSeason(selectedRims.info.tyre_type, intl));
    }
  }, [selectedRims, subModelRimsData]);

  return (
    <StyledContainer>
      <ImageWrapper imageSrc={userCarPhoto.base64}>
        <ImageWrapper imageSrc={wheelsImageWrapperSrc || userCarPhoto.base64}>
          <StyledConfirmSvgButton onClick={() => onConfirm()} className="ConfirmButton">
            <ConfirmSvg />
          </StyledConfirmSvgButton>

          <BottomBar>
            <StyledBottomBar>
              {availablesSeasonsAndSizes && availablesSeasonsAndSizes.length && selectedSeason && selectedSize && (
                <RimsMenuComponent
                  intl={intl}
                  availablesSeasonsAndSizes={availablesSeasonsAndSizes}
                  currentSeason={selectedSeason}
                  currentSize={selectedSize}
                  // user select rims size
                  setSelectedRimSize={setSelectedSize}
                  // user select rims season
                  setSelectedRimSeason={setSelectedSeason}
                  selectedRims={selectedRims}
                  applyFilter={setApplyFilter}
                  appliedFilter={applyFilter}
                />
              )}

              <RimsContainer>
                <HorizontalScrollBox
                  display='flex'
                  pxToScroll={rimSize + rimMargin * 2}
                  width="calc(100vw - 235px)"
                  additionalStyles="
                    height: 150%;
                    position: relative;
                    top: -25px;
                    overflow-y: hidden;
                  "
                >
                    {rims.map(rim => (
                      <RimButton key={rim.id} type="button" onClick={() => setSelectedRim(rim)} selected={selectedRims && selectedRims.id === rim.id}>
                        <RimImg src={rim.thumbnail} alt={rim.name} />
                      </RimButton>
                    ))}
                    <LastRimPad />
                </HorizontalScrollBox>
              </RimsContainer>
            </StyledBottomBar>
          </BottomBar>
        </ImageWrapper>
      </ImageWrapper>
    </StyledContainer>
  );
}

PickWheels.propTypes = {
  intl: intlShape.isRequired,
  userCarPhoto: PropTypes.objectOf(PropTypes.string).isRequired,
  onConfirm: PropTypes.func.isRequired
};

export default injectIntl(PickWheels);
