import React from 'react';
import styled from 'styled-components';

import { darkGrey, zIndexes } from '../../constants';

export const BottomBarContainer = styled.div`
  height: 52px;
  background: ${darkGrey}
  width: 100%;
  position: fixed;
  bottom: 0;
  z-index: ${zIndexes.header};
  color: white;
  font-size: 12px;
  font-family: AudiTypeScreen;
`;


export function BottomBar({ children }) {
  return (
    <BottomBarContainer>
      {children}
    </BottomBarContainer>
  )
}