import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { EnchancedCameraScreen } from './CameraScreen';
import { getApplicationData } from '../../actions/applicationData';
import { setApplicationStateNextLocation } from '../../actions/applicationState';
import { setUserCarPhoto, removeUserCarPhoto } from '../../actions/userCarPhoto';
import { setWheelsPosition } from '../../actions/wheelsPosition';
import { setSelectedSeries } from '../../actions/selectedSeries';
import { setSelectedSubModel } from '../../actions/selectedSubModel';

function ConnectedCameraScreen(props) {
  return (
    <EnchancedCameraScreen {...props} />
  );
}

function mapStateToProps({ applicationData, selectedSubModel, applicationState, userCarPhoto, wheelsPosition }) {
  return {
    applicationData,
    selectedSubModel,
    blockHistoryPush: applicationState.blockHistoryPush,
    userCarPhoto,
    wheelsPosition
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getApplicationData,
    setApplicationStateNextLocation,
    setUserCarPhoto,
    removeUserCarPhoto,
    setWheelsPosition,
    setSelectedSeries,
    setSelectedSubModel
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ConnectedCameraScreen);