import { setUserCarPhotoActionType, removeUserCarPhotoActionType } from '../actions/userCarPhoto';

const initialState = {
  base64: null
}

const userCarPhoto = (state = initialState, action) => {
  switch (action.type) {
    case `${setUserCarPhotoActionType}`:
      return { base64: action.payload };
    case `${removeUserCarPhotoActionType}`:
      return initialState;
    default:
      return state;
  }
};

export default userCarPhoto;