import React from 'react';
import PropTypes from 'prop-types';

import { ModelPropTypes } from '../ModelSelect/ModelSelect';
import { Tile, TilesContainer } from '../../../../components/Tile/Tile';

export const SeriesSelect = ({ series = [], onSeriesSelect }) => {
  const orderedSeries = series.sort((a, b) => +a.order - +b.order);
  return (
    <TilesContainer>
      {series &&
        orderedSeries.map(item => (
          <Tile key={item.id} thumbnail={item.thumbnail} altText={item.name} text={item.name} textSize={22} onTileClick={() => onSeriesSelect(item)} />
        ))}
    </TilesContainer>
  );
};

export const SeriesPropTypes = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  thumbnail: PropTypes.string.isRequired,
  models: PropTypes.arrayOf(ModelPropTypes)
});

SeriesSelect.propTypes = {
  series: PropTypes.arrayOf(SeriesPropTypes),
  onSeriesSelect: PropTypes.func.isRequired
};

SeriesSelect.defaultProps = {
  series: []
};
