import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { ModelsScreen } from './ModelsScreen';
import { setSelectedSubModel, removeSelectedSubModel } from '../../actions/selectedSubModel';
import { setSelectedSeries, removeSelectedSeries } from '../../actions/selectedSeries';

function ConnectedModelsScreen(props) {
  return (
    <ModelsScreen {...props} />
  );
}

function mapStateToProps({ applicationData, selectedSubModel, selectedSeries, selectedModel }) {
  return {
    applicationData,
    selectedSubModel,
    selectedSeries,
    selectedModel
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    setSelectedSubModel,
    removeSelectedSeries,
    setSelectedSeries,
    removeSelectedSubModel
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ConnectedModelsScreen);