import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

import { AlertWrapperComponent } from '../../shared-ui';
import rotateDevice from '../../assets/ico/rotate_device_ico.png';
import { darkGrey } from '../../constants';

const Img = styled.img`
  max-height: 98px;
`;

const Message = styled.div`
  margin: 33px 25px;
  text-align: center;
  font-size: 22pt;
  line-height: 28pt;
  color: ${darkGrey};
`;

export const BadOrientationScreen = () => (
    <AlertWrapperComponent>
        <Img src={rotateDevice} alt="rotate device hint" />
        <Message>
            <FormattedMessage id="bad-orientation-screen.message" />
        </Message>
    </AlertWrapperComponent>
);