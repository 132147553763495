const setSelectedSeriesActionType = 'SET_SELECTED_SERIES';
const removeSelectedSeriesActionType = 'REMOVE_SELECTED_SERIES';

const setSelectedSeriesAction = (data) => ({
  type: setSelectedSeriesActionType,
  payload: data
});

const removeSelectedSeriesAction = () => ({
  type: removeSelectedSeriesActionType
});

const setSelectedSeries = (data) => dispatch => {
  dispatch(setSelectedSeriesAction(data));
};

const removeSelectedSeries = () => dispatch => {
  dispatch(removeSelectedSeriesAction());
};

export {
  setSelectedSeries,
  removeSelectedSeries,
  setSelectedSeriesActionType,
  removeSelectedSeriesActionType
}