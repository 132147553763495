const setSelectedRimActionType = 'SET_SELECTED_RIM';
const removeSelectedRimActionType = 'REMOVE_SELECTED_RIM';

const setSelectedRimAction = data => ({
  type: setSelectedRimActionType,
  payload: data
});

const removeSelectedRimAction = () => ({
  type: removeSelectedRimActionType
});

const setSelectedRim = data => dispatch => {
  dispatch(setSelectedRimAction(data));
};

const removeSelectedRim = () => dispatch => {
  dispatch(removeSelectedRimAction());
};

export {
  setSelectedRim,
  removeSelectedRim,
  setSelectedRimActionType,
  removeSelectedRimActionType
};
