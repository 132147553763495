import styled from 'styled-components';

import { zIndexes } from '../../../constants/styleConstants';

export const StyledWebcamContainer = styled.div`
  width: 100vw;
  height: 100%;
  overflow: hidden;
  position: absolute;
  z-index: ${zIndexes.camera};
  top: 0;
`;