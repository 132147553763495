import React, { useRef, useState, Fragment } from 'react';
import Webcam from 'react-webcam';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import innerHeight from 'ios-inner-height';
import { isTablet, isSafari } from 'react-device-detect';
import { Stencil, CameraButton } from '../../../../components';
import { ReactComponent as TakePictureSvg } from '../../../../assets/images/take_picture_icon.svg';
import { ReactComponent as FlipShapeSvg } from '../../../../assets/images/flip_shape_icon.svg';
import { InfoBottomBar } from '../../../../components/BottomBar';
import { StyledWebcamContainer } from '../../../../helpers/styles/layout';
import { cameraImageSize } from '../../../../constants/appConstants';
import { zIndexes } from '../../../../constants';

const StyledTakePhotoButton = styled(CameraButton)`
  right: 10px;
  top: calc(50% - 30px);

  @media only screen and (max-width: 480px) {
    right: 5px;
  }
`;

const StyledFlipStencilButton = styled(CameraButton)`
  right: 10px;
  top: calc(50% + 30px);

  @media only screen and (max-width: 480px) {
    right: 5px;
  }
`;

const StyledContainer = styled.div`
  background-color: black;
`;

const CameraError = styled.div`
  position: absolute;
  z-index: ${zIndexes.stencil};
  top: 50%;
  width: 100%;
  text-align: center;
  transform: translate(0, -50%);
`;

function CarPhotoCapture(props) {
  const { selectedSubModel, width, height, onPhotoCapture, intl } = props;
  const containerRef = useRef(null);
  const webcamRef = useRef(null);
  const stencilRef = useRef(null);
  const [isMirror, setIsMirror] = useState(false);
  const [streamIsReady, setStreamIsReady] = useState(false);
  const [cameraAccess, setCameraAccess] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');

  const onCapture = () => {
    const image = stencilRef.current;
    const boundingClientRect = image.getBoundingClientRect();

    const widthRatio = boundingClientRect.width / image.naturalWidth;
    const heigthRatio = boundingClientRect.height / image.naturalHeight;

    // iOS tablet navbar is always visible, so innerHeight() method return bad device height
    const top = ((isTablet && isSafari ? window.innerHeight : innerHeight()) - boundingClientRect.height) / 2;

    let frontPositionX = boundingClientRect.left + selectedSubModel['coordinates-front-x'] * widthRatio;
    const frontPositionY = top + selectedSubModel['coordinates-front-y'] * heigthRatio;
    let rearPositionX = boundingClientRect.left + selectedSubModel['coordinates-rear-x'] * widthRatio;
    const rearPositionY = top + selectedSubModel['coordinates-rear-y'] * heigthRatio;

    // If stencil is inMirror state
    if (isMirror) {
      frontPositionX = window.innerWidth - frontPositionX;
      rearPositionX = window.innerWidth - rearPositionX;
    }
    const positionData = {
      deviceSizeImage: {
        frontPositionX,
        frontPositionY,
        rearPositionX,
        rearPositionY,
        radius: (image.naturalHeight - selectedSubModel['coordinates-front-y']) * widthRatio
      },
      isMirror
    };

    const photoSrc = webcamRef.current.getScreenshot();

    onPhotoCapture(photoSrc, positionData);
  };

  const onFlipStencil = () => {
    setIsMirror(!isMirror);
  };

  const videoConstraints = {
    width: cameraImageSize.width,
    height: cameraImageSize.height,
    facingMode: 'environment'
  };

  const userMediaReady = () => {
    setCameraAccess(true);
    setStreamIsReady(true);
  };

  const onUserMediaError = error => {
    setCameraAccess(false);
    setStreamIsReady(false);

    switch (error.name) {
      case 'AbortError':
      case 'NotFoundError':
      case 'NotReadableError':
      case 'OverconstrainedError':
      case 'SecurityError':
      default: {
        return setErrorMessage(intl.formatMessage({ id: 'camera-screen.car-photo-capture.camera-error.generic' }));
      }

      case 'NotAllowedError': {
        return setErrorMessage(intl.formatMessage({ id: 'camera-screen.car-photo-capture.camera-error.access-deny' }));
      }
    }
  };

  return (
    <StyledContainer ref={containerRef}>
      {width && height && (
        <StyledWebcamContainer>
          <Webcam
            audio={false}
            width={width}
            height={height}
            ref={webcamRef}
            onUserMedia={userMediaReady}
            onUserMediaError={onUserMediaError}
            screenshotQuality={1}
            minScreenshotWidth={videoConstraints.width}
            minScreenshotHeight={videoConstraints.height}
            screenshotFormat="image/png"
            videoConstraints={videoConstraints}
            style={{ height: `100%`, width: `100%`, objectFit: 'cover' }}
          />
        </StyledWebcamContainer>
      )}
      {streamIsReady && (
        <Fragment>
          <StyledTakePhotoButton onClick={onCapture} className="TakePhotoButton">
            <TakePictureSvg />
          </StyledTakePhotoButton>
          <StyledFlipStencilButton onClick={onFlipStencil} className="FlipStencilButton">
            <FlipShapeSvg />
          </StyledFlipStencilButton>
        </Fragment>
      )}
      {cameraAccess ? <Stencil isMirror={isMirror} stencilUrl={selectedSubModel.stencil} stencilRef={stencilRef} /> : <CameraError>{errorMessage}</CameraError>}
      <InfoBottomBar>
        <FormattedMessage id="camera-screen.car-photo-capture.info-text" />
      </InfoBottomBar>
    </StyledContainer>
  );
}

CarPhotoCapture.propTypes = {
  intl: intlShape.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  onPhotoCapture: PropTypes.func.isRequired
};

export default injectIntl(CarPhotoCapture);
