import React, { useEffect, useState } from 'react';
// import styled from 'styled-components';

import { AlertWrapperComponent, ModalComponent } from '../../shared-ui';
import deviceHint from '../../assets/images/incompatible.png';
// import { darkGrey } from '../../constants';

import { BadDeviceMessageComponent } from './BadDeviceModal';

// const Message = styled.div`
//   max-width: 576px;
//   margin: 55px 0;
//   text-align: center;
//   font-size: 16pt;
//   font-weight: 300;
//   line-height: 24pt;
//   color: ${darkGrey};

//   @media (max-height: 400px){
//     margin: 25px 0;
//   }
// `;

export const BadDeviceScreen = () => {
  const [isModalVisible, toggleVisibility] = useState(false);

  useEffect(() => {
    setTimeout(() => toggleVisibility(true), 3000);
  }, []);

  return (
    <AlertWrapperComponent>
      <img src={deviceHint} alt="bad device hint" />
      {isModalVisible ? (
        <ModalComponent isBadDevice>
          <BadDeviceMessageComponent />
        </ModalComponent>
      ) : (
        <p />
      )}
    </AlertWrapperComponent>
  );
};
