import {
  setSelectedRimActionType,
  removeSelectedRimActionType
} from '../actions/selectedRims';

const initialState = null;

const selectedRims = (state = initialState, action) => {
  switch (action.type) {
    case `${setSelectedRimActionType}`:
      return action.payload;
    case `${removeSelectedRimActionType}`:
      return initialState;
    default:
      return state;
  }
};

export default selectedRims;
