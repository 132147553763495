export const loadImgages = function loadImg(src) {
  const paths = Array.isArray(src) ? src : [src];
  const promise = [];

  paths.forEach(path => {
    promise.push(
      new Promise((resolve, reject) => {
        const img = new Image();
        img.crossOrigin = 'Anonymous';

        img.onload = () => {
          resolve({
            path,
            img
          });
        };

        img.onerror = error => {
          reject(error);
        };

        img.src = path;
      })
    );
  });

  return Promise.all(promise);
};
