import { setWheelsPositionActionType, resetWheelsPositionActionType } from '../actions/wheelsPosition';

const initialState = {
  deviceSizeImage: {},
  fullImage: {}
}

const wheelsPosition = (state = initialState, action) => {
  switch (action.type) {
    case `${setWheelsPositionActionType}`:
      return action.payload;
    case `${resetWheelsPositionActionType}`:
      return initialState;
    default:
      return state;
  }
};

export default wheelsPosition;