import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import App from './App';
import { getApplicationData } from './actions/applicationData';

function ConnectedApp(props) {
  return (
    <App {...props} />
  );
}

function mapStateToProps({ applicationData }) {
  return {
    applicationData,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getApplicationData
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ConnectedApp);