import { setSelectedModelActionType, removeSelectedModelActionType } from '../actions/selectedModel';

const initialState = null;

const selectedModel = (state = initialState, action) => {
  switch (action.type) {
    case `${setSelectedModelActionType}`:
      return action.payload;
    case `${removeSelectedModelActionType}`: {
      return initialState
    }

    default:
      return state;
  }
};

export default selectedModel