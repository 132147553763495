import React, { useEffect } from 'react';

import { WheelsPositioning } from './components/WheelsPositioning';
import { withDeviceSize } from '../../helpers/withDeviceSize';
import { calculateDrawPositionForContainMode } from '../../helpers/calculateDrawPositionForContainMode';
import { cameraImageSize } from '../../constants/appConstants';

export function WheelsPositioningScreen(props) {
  const { removeUserCarPhoto, userCarPhoto, setWheelsPosition, removeWheelsPosition, wheelsPosition, history, width, height, match } = props;
  const { seriesId, subModelId } = match.params;

  useEffect(() => {
    if (!userCarPhoto.base64) {
      history.push(`/models/${seriesId}/submodels/${subModelId}/camera`);
    }
  }, [userCarPhoto]);

  const onReset = () => {
    removeUserCarPhoto();
    removeWheelsPosition();
    history.goBack();
  };

  const onConfirm = data => {
    const front = data.find(wheel => wheel.name === 'front');
    const rear = data.find(wheel => wheel.name === 'rear');

    const { ratio, centerShiftX, centerShiftY } = calculateDrawPositionForContainMode(width, height, cameraImageSize.width, cameraImageSize.height);

    // Position of wheel on image with device size
    const frontPositionXDevice = front.x - centerShiftX;
    const frontPositionYDevice = front.y - centerShiftY;
    const rearPositionXDevice = rear.x - centerShiftX;
    const rearPositionYDevice = rear.y - centerShiftY;

    // Position of wheel on full image (1920x1080)
    const frontPositionXImage = frontPositionXDevice / ratio;
    const frontPositionYImage = frontPositionYDevice / ratio;
    const rearPositionXImage = rearPositionXDevice / ratio;
    const rearPositionYImage = rearPositionYDevice / ratio;

    // Radius of wheel on full image (1920x1080)
    const radiusImage = wheelsPosition.deviceSizeImage.radius / ratio;

    setWheelsPosition({
      ...wheelsPosition,
      deviceSizeImage: {
        ...wheelsPosition.deviceSizeImage,
        frontPositionX: front.x,
        frontPositionY: front.y,
        rearPositionX: rear.x,
        rearPositionY: rear.y
      },
      fullImage: {
        frontPositionX: frontPositionXImage,
        frontPositionY: frontPositionYImage,
        rearPositionX: rearPositionXImage,
        rearPositionY: rearPositionYImage,
        radius: radiusImage
      }
    });

    history.push(`/models/${seriesId}/submodels/${subModelId}/pick-wheels`);
  };

  return (
    <div>
      {userCarPhoto && userCarPhoto.base64 && (
        <WheelsPositioning
          width={width}
          height={height}
          imageSrc={userCarPhoto.base64}
          onReset={onReset}
          onConfirm={onConfirm}
          wheelsPosition={wheelsPosition}
        />
      )}
    </div>
  );
}

export const EnchancedWheelsPositioningScreen = withDeviceSize(WheelsPositioningScreen);
