import { combineReducers } from 'redux';

import applicationData from './applicationData';
import selectedSubModel from './selectedSubModel';
import selectedModel from './selectedModel';
import selectedSeries from './selectedSeries';
import applicationState from './applicationState';
import userCarPhoto from './userCarPhoto';
import wheelsPosition from './wheelsPosition';
import selectedRims from './selectedRims';
import userCarPhotoWithNewWheels from './userCarPhotoWithNewWheels';

const rootReducer = combineReducers({
  applicationData,
  selectedSubModel,
  selectedModel,
  selectedSeries,
  applicationState,
  userCarPhoto,
  wheelsPosition,
  selectedRims,
  userCarPhotoWithNewWheels
});

export default rootReducer;
