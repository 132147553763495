import { setUserCarPhotoWithNewWheelsActionType } from '../actions/userCarPhotoWithNewWheels';

const userCarPhotoWithNewWheels = (state = null, action) => {
  switch (action.type) {
    case `${setUserCarPhotoWithNewWheelsActionType}`:
      return { base64: action.payload };
    default:
      return state;
  }
};

export default userCarPhotoWithNewWheels;