import { applicationStateBlockHistoryPushActionType, setApplicationStateNextLocationActionType } from '../actions/applicationState';

const initialState = {
  blockHistoryPush: false,
  nextLocation: null
}

const applicationState = (state = initialState, action) => {
  switch (action.type) {
    case `${applicationStateBlockHistoryPushActionType}`:
      return {
        ...state,
        blockHistoryPush: action.payload
      }
    case `${setApplicationStateNextLocationActionType}`:
      return {
        ...state,
        nextLocation: action.payload
      }
    default:
      return state;
  }
}

export default applicationState;