import { setSelectedSubModelActionType, removeSelectedSubModelActionType } from '../actions/selectedSubModel';

const initialState = null;

const selectedSubModel = (state = initialState, action) => {
  switch (action.type) {
    case `${setSelectedSubModelActionType}`:
      return action.payload;
    case `${removeSelectedSubModelActionType}`:
      return initialState;
    default:
      return state;
  }
};

export default selectedSubModel